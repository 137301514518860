import React from "react";
import "./TermCondition.View.scss";
import { Accordion, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AccordionButtonToggle } from "../complete-privacy-policy/CompletePrivacyPolicy.View";

const TermCondition = ({ togglePrivacyPolicy }) => {
  return (
    <div className="body-wrapper-term-condition">
      <div className="term-condition-header-container">
        <h1 className="heading-term-condition">
          Terms and Conditions of <strong>floatpic.com</strong>
        </h1>
        <p className="head-para-term-condition pb-2">These Terms govern</p>
        <ul>
          <li>the use of this Website, and,</li>
          <li>
            any other related Agreement or legal relationship with the Owner
          </li>
        </ul>
        <p className="head-para-term-condition pb-3">
          in a legally binding way. Capitalized words are defined in the
          relevant dedicated section of this document.
        </p>
        <p className="head-para-term-condition pb-4 dotted-border">
          The User must read this document carefully
        </p>
        <p className="head-para-term-condition pt-3">
          This Website is provided by:
        </p>
        <p className="single-content-para pt-3">FloatPic Inc</p>
        <p className="single-content-para">4025 Dorchester Rd.</p>
        <p className="single-content-para">Suite 336</p>
        <p className="single-content-para">Niagara Falls, Ontario L2E 7K8</p>
        <p className="single-content-para mb-3">Canada</p>
        <p className="single-content-para">
          These policies are applicable to FloatPic Inc.
        </p>
        <p className="single-content-para mb-3">worldwide sites:</p>
        <p className="single-content-para">floatpic.com</p>
        <p className="single-content-para">ca.floatpic.com</p>
        <p className="single-content-para">uk.floatpic.com</p>
        <p className="single-content-para mb-3">au.floatpic.com</p>
        <p className="single-content-para">
          <strong className="email-heading pb-3 dotted-border">
            Owner contact email:
          </strong>
          admin@floatpic.com
        </p>
      </div>

      <h1 className="heading-term-condition-use">TERMS OF USE</h1>
      <p className="head-para-term-condition pb-3">
        Unless otherwise specified, the terms of use detailed in this section
        apply generally when using this Website.
      </p>
      <p className="head-para-term-condition pb-3">
        Single or additional conditions of use or access may apply in specific
        scenarios and in such cases are additionally indicated within this
        document.
      </p>
      <p className="head-para-term-condition pb-3">
        By using this Website, Users confirm to meet the following requirements:
      </p>
      <h1 className="heading-term-condition-content-web">
        Content on this Website
      </h1>
      <p className="head-para-term-condition pb-3">
        Unless where otherwise specified or clearly recognizable, all content
        available on this Website is owned or provided by the Owner or its
        licensors.
      </p>
      <p className="head-para-term-condition pb-3">
        The Owner undertakes its utmost effort to ensure that the content
        provided on this Website infringes no applicable legal provisions or
        third-party rights. However, it may not always be possible to achieve
        such a result. In such cases, without prejudice to any legal
        prerogatives of Users to enforce their rights, Users are kindly asked to
        preferably report related complaints using the contact details provided
        in this documen
      </p>

      <h1 className="heading-term-condition-content-web">
        Access to external resources
      </h1>
      <p className="head-para-term-condition pb-3">
        Through this Website Users may have access to external resources
        provided by third parties. Users acknowledge and accept that the Owner
        has no control over such resources and is therefore not responsible for
        their content and availability.
      </p>
      <p className="head-para-term-condition pb-3">
        Conditions applicable to any resources provided by third parties,
        including those applicable to any possible grant of rights in content,
        result from each such third parties’ terms and conditions or, in the
        absence of those, applicable statutory law.
      </p>

      <h1 className="heading-term-condition-content-web">Acceptable use</h1>
      <p className="head-para-term-condition pb-3">
        This Website and the Service may only be used within the scope of what
        they are provided for, under these Terms and applicable law.
      </p>
      <p className="head-para-term-condition pb-3">
        Users are solely responsible for making sure that their use of this
        Website and/or the Service violates no applicable law, regulations or
        third-party rights.
      </p>

      <h1 className="heading-term-condition-use">Common provisions</h1>
      <h1 className="heading-term-condition-content-web">No Waiver</h1>
      <p className="head-para-term-condition pb-3">
        The Owner’s failure to assert any right or provision under these Terms
        shall not constitute a waiver of any such right or provision. No waiver
        shall be considered a further or continuing waiver of such term or any
        other term.
      </p>
      <h1 className="heading-term-condition-content-web">
        Service interruption
      </h1>
      <p className="head-para-term-condition pb-3">
        To ensure the best possible service level, the Owner reserves the right
        to interrupt the Service for maintenance, system updates or any other
        changes, informing the Users appropriately.
      </p>
      <p className="head-para-term-condition pb-3">
        Within the limits of law, the Owner may also decide to suspend or
        terminate the Service altogether. If the Service is terminated, the
        Owner will cooperate with Users to enable them to withdraw Personal Data
        or information in accordance with applicable law.
      </p>
      <p className="head-para-term-condition pb-3">
        Additionally, the Service might not be available due to reasons outside
        the Owner’s reasonable control, such as “force majeure” (eg. labor
        actions, infrastructural breakdowns or blackouts etc).
      </p>

      <h1 className="heading-term-condition-content-web">Service reselling</h1>
      <p className="head-para-term-condition pb-3">
        Users may not reproduce, duplicate, copy, sell, resell or exploit any
        portion of this Website and of its Service without the Owner’s express
        prior written permission, granted either directly or through a
        legitimate reselling programme.
      </p>

      <h1 className="heading-term-condition-content-web">
        Intellectual property rights
      </h1>
      <p className="head-para-term-condition pb-3">
        Without prejudice to any more specific provision of these Terms, any
        intellectual property rights, such as copyrights, trademark rights,
        patent rights and design rights related to this Website are the
        exclusive property of the Owner or its licensors and are subject to the
        protection granted by applicable laws or international treaties relating
        to intellectual property.
      </p>
      <p className="head-para-term-condition pb-3">
        All trademarks — nominal or figurative — and all other marks, trade
        names, service marks, word marks, illustrations, images, or logos
        appearing in connection with this Website are, and remain, the exclusive
        property of the Owner or its licensors and are subject to the protection
        granted by applicable laws or international treaties related to
        intellectual property.
      </p>

      <h1 className="heading-term-condition-content-web">
        Changes to these Terms
      </h1>
      <p className="head-para-term-condition pb-3">
        The Owner reserves the right to amend or otherwise modify these Terms at
        any time. In such cases, the Owner will appropriately inform the User of
        these changes
      </p>
      <p className="head-para-term-condition pb-3">
        Such changes will only affect the relationship with the User for the
        future.
      </p>
      <p className="head-para-term-condition pb-3">
        The continued use of the Service will signify the User’s acceptance of
        the revised Terms. If Users do not wish to be bound by the changes, they
        must stop using the Service. Failure to accept the revised Terms, may
        entitle either party to terminate the Agreement.
      </p>
      <p className="head-para-term-condition pb-3">
        The applicable previous version will govern the relationship prior to
        the User's acceptance. The User can obtain any previous version from the
        Owner.
      </p>

      <h1 className="heading-term-condition-content-web">
        Assignment of contract
      </h1>
      <p className="head-para-term-condition pb-3">
        The Owner reserves the right to transfer, assign, dispose of by
        novation, or subcontract any or all rights or obligations under these
        Terms, taking the User’s legitimate interests into account. Provisions
        regarding changes of these Terms will apply accordingly.
      </p>
      <p className="head-para-term-condition pb-3">
        Users may not assign or transfer their rights or obligations under these
        Terms in any way, without the written permission of the Owner.
      </p>

      <h1 className="heading-term-condition-content-web">Contacts</h1>
      <p className="head-para-term-condition pb-3">
        All communications relating to the use of this Website must be sent
        using the contact information stated in this document.
      </p>

      <h1 className="heading-term-condition-content-web">Severability</h1>
      <p className="head-para-term-condition pb-3 dotted-border">
        Should any provision of these Terms be deemed or become invalid or
        unenforceable under applicable law, the invalidity or unenforceability
        of such provision shall not affect the validity of the remaining
        provisions, which shall remain in full force and effect.
      </p>

      <div className="content-wrapper pb-4 dotted-border">
        <Row>
          <Col lg={12}>
            <Accordion>
              <Card>
                <Card.Header>
                  <AccordionButtonToggle eventKey="0">
                    <div className="d-flex button-container">
                      <h2 className="single-text-accordion">
                        Definitions and legal references
                      </h2>
                    </div>
                  </AccordionButtonToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0" className="accordion-body">
                  <Card.Body>
                    <h2 className="single-content-sub-heading pt-0">
                      This Website (or this Application)
                    </h2>
                    <p className="single-content-para pb-3">
                      The property that enables the provision of the Service.
                    </p>
                    <h2 className="single-content-sub-heading pt-0">
                      Agreement
                    </h2>
                    <p className="single-content-para pb-3">
                      Any legally binding or contractual relationship between
                      the Owner and the User, governed by these Terms.
                    </p>
                    <h2 className="single-content-sub-heading pt-0">
                      Owner (or We)
                    </h2>
                    <p className="single-content-para pb-3">
                      Indicates the natural person(s) or legal entity that
                      provides this Website and/or the Service to Users
                    </p>
                    <h2 className="single-content-sub-heading pt-0">Service</h2>
                    <p className="single-content-para pb-3">
                      The service provided by this Website as described in these
                      Terms and on this Website.
                    </p>

                    <h2 className="single-content-sub-heading pt-0">Terms</h2>
                    <p className="single-content-para pb-3">
                      All provisions applicable to the use of this Website
                      and/or the Service as described in this document,
                      including any other related documents or agreements, and
                      as updated from time to time.
                    </p>

                    <h2 className="single-content-sub-heading pt-0">
                      User (or You)
                    </h2>
                    <p className="single-content-para pb-3">
                      Indicates any natural person or legal entity using this
                      Website.
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </div>
      <div className="footer-wrapper">
        <p className="para-frist">Latest update: December 29, 2022</p>
        <p className="para-second">
          <Link
            to="https://www.iubenda.com/en/privacy-and-term-condition-policy-generator"
            className="single-link"
          >
            iubenda
          </Link>{" "}
          hosts this content and only{" "}
          <Link
            to="https://www.iubenda.com/privacy-policy/65675001"
            className="single-link"
          >
            collects the Personal Data strictly necessary
          </Link>{" "}
          for it to be provided.
        </p>
      </div>
    </div>
  );
};
export default TermCondition;

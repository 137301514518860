import React, {Fragment, useEffect, useState} from 'react';
import CookiePolicyModal from '../cookie-policy-modal';
import PrivacyPolicyModal from '../privacy-policy-modal/PrivacyPolicyModal.View';
import TermConditionModal from '../term-condition-modal/TermConditionModal.View';
import './RequisiteInformationTab.View.scss';
import {getCountry} from "../../../utils/config";
import {useOrder} from "../../../contexts/OrderContext";

const RequisiteInformationTab = () => {
    const {
        priceDetails,
    } = useOrder();

    useEffect(() => {
        if (window !== undefined) {
            var loader = function () {
                var s = document.createElement('script');
                s.src = 'https://cdn.iubenda.com/iubenda.js';
                document.body.insertAdjacentElement('afterbegin', s);
            };
            loader();
        }
        console.log(priceDetails)
    }, [priceDetails]);
    const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);
    const [cookiePolicyModalOpen, setCookiePolicyModalOpen] = useState(false);
    const [termConditionModalOpen, setTermConditionModalOpen] = useState(false);
    const togglePrivacyPolicyModal = () => {
        sessionStorage.setItem('modalOpen', !privacyPolicyModalOpen);

        setPrivacyPolicyModalOpen((prev) => !prev);
    };

    const toggleCookiePolicyModal = () => {
        sessionStorage.setItem('modalOpen', !cookiePolicyModalOpen);
        setCookiePolicyModalOpen((prev) => !prev);
    };
    const toggleTermConditionModal = () => {
        sessionStorage.setItem('modalOpen', !termConditionModalOpen);
        setTermConditionModalOpen((prev) => !prev);
    };

    const currentCountry = getCountry();

    return (
        <div className='customer-service-tab-container'>
            <Fragment>
                <h4 className='single-header'>Founding Principles</h4>
                <p className='single-para'>
                    FloatPic is a recently established organization dedicated to the development of
                    the ultimate physical representation of a still image for wall display, free
                    from any subjective biases towards the artist or the artwork.
                </p>
                <p className='single-para'>
                    Our methodology is founded in the philosophy of "The Science of Aesthetics, or,
                    The Nature, Kinds, Laws, and Uses of Beauty," authored by Henry Noble Day in
                    1872. This philosophy serves as the foundation for our commitment to the highest
                    standards of design, material selection, and craftsmanship.
                </p>
            </Fragment>

            <Fragment>
                <h4 className='single-header single-header-sub'>The Signature Design</h4>
                <p className='single-para'>
                    The image and border are printed directly onto one glass panel, separated by a
                    transparent space, and sealed on the backside with a second glass panel, which
                    has a black appearance. The resulting 4mm ultra-thin assembly is mounted away
                    from the wall using concealed hardware provided. Border options include
                    TrueBorder™ and Black/White. Models are designated by their size, measured
                    diagonally from the corners, and displayed on each item with the artist's name.
                </p>

                <p className='para-bottom'>
                    20 inches
                    - {`${priceDetails?.currency_code || ''} ${priceDetails?.currency_sign || ''}${new Intl.NumberFormat().format(priceDetails?.S40_price || 0)}`}
                </p>

                <p className='para-bottom'>
                    40 inches
                    - {`${priceDetails?.currency_code || ''} ${priceDetails?.currency_sign || ''}${new Intl.NumberFormat().format(priceDetails?.S55_price || 0)}`}
                </p>
            </Fragment>

            <Fragment>
                <h4 className='single-header single-header-sub'>Acquisition</h4>
                <p className='single-para'>
                    To ensure a clear and seamless acquisition process, full payment is required at
                    the time the order is placed and includes all financial obligations, including
                    taxes, shipping fees, import duties, and other associated costs. All
                    transactions are secured with an SSL encryption system to protect our customers
                    personal and payment data.
                </p>
                <p className='single-para'>
                    Each order placed with us is made-to-order at our manufacturing and shipping
                    facility in Canada. This comprehensive procedure typically necessitates a
                    timeframe of up to three months from the initiation of the order before it is
                    dispatched for worldwide shipment.
                </p>
                <p className='single-para'>
                    We are fully committed to delivering exclusively unused products to our
                    customers. To uphold this commitment and the integrity of our operations, we
                    have instituted a policy that restricts returns, exchanges, or cancellations. In
                    the exceptional event of loss or damage incurred during transit, we guarantee
                    the replacement of the affected item at no extra expense to you.
                </p>
            </Fragment>
            <Fragment>
                <h4 className='single-header single-header-sub'>Legal</h4>
                <div className='d-flex flex-column link-container'>
                    <a
                        href='https://www.iubenda.com/privacy-policy/75519390'
                        className='dropdown-links iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe single-link-mail d-block'
                        title='Privacy Policy '
                    >
                        Privacy Policy
                    </a>
                    <a
                        href='https://www.iubenda.com/privacy-policy/75519390/cookie-policy'
                        className='iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe single-link-mail d-block'
                        title='Cookie Policy '
                    >
                        Cookie Policy
                    </a>
                    <a
                        href='https://www.iubenda.com/terms-and-conditions/75519390'
                        className='iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe single-link-mail d-block'
                        title='Terms and Conditions '
                    >
                        Terms and Conditions
                    </a>
                </div>
            </Fragment>
            <Fragment>
                <h4 className='single-header single-header-sub'>Contact</h4>
                <div className='d-flex flex-column link-container'>
                    <a
                        className='single-link single-link-mail d-block'
                        href='mailto:enquiries@floatpic.com'
                    >
                        enquiries@floatpic.com
                    </a>
                    <a
                        className='single-link single-link-mail d-block'
                        href='mailto:press@floatpic.com'
                    >
                        press@floatpic.com
                    </a>
                    <a
                        className='single-link single-link-mail d-block'
                        href='mailto:careers@floatpic.com'
                    >
                        careers@floatpic.com
                    </a>
                </div>
            </Fragment>
            <Fragment>
                <h4 className='single-header single-header-sub'>Worldwide</h4>
                <p className='single-para'>
                    You are currently visiting the{' '}
                    {(currentCountry.name) || 'All other countries'} store.
                </p>
                <div className='d-flex flex-column link-container'>
                    <a
                        href={process.env.REACT_APP_BASE_URL || 'https://floatpic.com'}
                        className='link-single d-block'
                        title={process.env.REACT_APP_BASE_URL}
                    >
                        <span className=' single-link-mail'>Change Location</span>
                    </a>
                </div>
                <div className='copy-right'>© FloatPic Inc., 2024. All rights reserved.</div>
            </Fragment>
            <PrivacyPolicyModal
                open={privacyPolicyModalOpen}
                toggle={togglePrivacyPolicyModal}
            />
            <CookiePolicyModal
                open={cookiePolicyModalOpen}
                toggle={toggleCookiePolicyModal}
            />
            <TermConditionModal
                open={termConditionModalOpen}
                toggle={toggleTermConditionModal}
            />
        </div>
    );
};
export default RequisiteInformationTab;

import {usePage} from '../../../contexts/PageContext';
import {Fragment} from "react";

const TempImages = () => {
    const {
        floatPictures,
        filteredFloatPictures,
    } = usePage();

    return (
        <Fragment>
            {floatPictures?.map((item) => (
                <div key={`temp_fp_${item.art_id}`}>
                    <img
                        src={item.imgTB}
                        alt='item'
                        style={{display: 'none'}}
                        loading="lazy"
                    />
                    <img
                        src={item.imgBW}
                        alt='item'
                        style={{display: 'none'}}
                        loading="lazy"
                    />
                </div>
            ))}
            {filteredFloatPictures?.map((item) => (
                <div key={`temp_ffp_${item.art_id}`}>
                    <img
                        src={item.imgTB}
                        alt='item'
                        style={{display: 'none'}}
                        loading="lazy"
                    />
                    <img
                        src={item.imgBW}
                        alt='item'
                        style={{display: 'none'}}
                        loading="lazy"
                    />
                </div>
            ))}
        </Fragment>
    );
};
export default TempImages;

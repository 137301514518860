import React, {memo, useContext} from 'react';
import {AccordionContext, useAccordionToggle} from 'react-bootstrap';
import './CollapseHeader.View.scss';

import './CollapseHeader.View.scss';

export const SingleListItem = memo(({children, eventKey, callback}) => {
    const currentEventKey = useContext(AccordionContext);
    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type='button'
            className={`single-list-button ${isCurrentEventKey ? 'active-list-button' : ''}`}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
});

import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useOrder} from '../../../../contexts/OrderContext';
import {usePage} from '../../../../contexts/PageContext';
import RecommendationsSlides from "./Recommendations";
import ArtistSlides from "./Artist";
import CartSlides from "./Cart";
import ZoomImageModal from "./ZoomModal";
import {appApiUrl, getProductMetaTitle} from "../../../../utils/config";
import {setCanonicalUrl} from "../../../../utils/common";

const Slides = () => {
    const navigate = useNavigate();
    const {
        cartItems,
        setCartItems,
    } = useOrder();
    const [isChangingState, setIsChangingState] = useState(false);
    const [swiper, setSwiper] = useState();

    const {
        showing,
        setShowing,
        floatPictures,
        setFloatPictures,
        borderPreference,
        filteredFloatPictures,
        setFilteredFloatPictures,
        currentSlider,
        setCurrentSlider,
        setLoading,
        idFromUrl,
        setCurrentSlide,
        reset,
        zoomedProduct
    } = usePage();

    const getProductWithId = (id) => {
        if (!id) return;
        setLoading(true);

        fetch(`${appApiUrl}/${id}/`)
            .then(async (res) => {
                const {art_data} = await res.json();
                if (art_data) {
                    let [art_id, art_name, artist_name, imgTB, imgBW] = art_data;
                    const newData = {
                        art_id,
                        art_name,
                        artist_name,
                        imgTB,
                        imgBW,
                        img: borderPreference === 'TB' ? imgTB : imgBW,
                    };
                    setFloatPictures([newData]);
                    // document.title = art_name + ' by ' + artist_name + ' | FloatPic.com';
                    setShowing('allProducts');
                    setCurrentSlider('products');
                    setCurrentSlide(id);
                    navigate(`/product/${id}`)
                } else {
                    reset();
                }
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handelInit = () => {
        if (!idFromUrl) return;
        const foundInFloatPic = floatPictures.find((item) => item.art_id === idFromUrl);
        const foundInFilteredFloatPic = filteredFloatPictures.find(
            (item) => item.art_id === idFromUrl
        );
        if (showing === 'orderList') {
            setCurrentSlider('orders');
        } else if (foundInFilteredFloatPic) {
            setShowing('allProducts')
            setCurrentSlider('filteredProducts')
            setCurrentSlide(idFromUrl)
        } else if (foundInFloatPic) {
            setShowing('allProducts')
            setCurrentSlider('products')
            setCurrentSlide(idFromUrl)
        } else {
            getProductWithId(idFromUrl)
        }
    }

    const loadNewProduct = async (amount = 2) => {
        await fetch(`${appApiUrl}/load_new_products/?no_of_items=${amount}`)
            .then(async (res) => {
                const resp = await res.json();
                const data = resp?.products_data
                    .map(([art_id, art_name, artist_name, imgTB, imgBW]) => {
                        return {
                            art_id,
                            art_name,
                            artist_name,
                            imgTB,
                            imgBW,
                            img: borderPreference === '-TB' ? imgTB : imgBW,
                        };
                    })
                    .filter(({art_id}) => {
                        const alreadyExist = floatPictures.find((item) => item.art_id === art_id);
                        return !alreadyExist;
                    });

                const pictures = data.map((item) => {
                    item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
                    return item;
                });
                setFloatPictures((prev) => [...prev, ...pictures]);
            })
            .catch(e => {
                console.log(e)
            });
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (window.location.pathname === '/') {
            reset()
        }
    }, [window.location]);

    useEffect(() => {
        handelInit();
    }, []);

    useEffect(() => {
        if (showing === 'allProducts') {
            (async () => {
                if (!idFromUrl) {
                    await loadNewProduct(3);
                }
            })();
        }
    }, [showing]);

    useEffect(() => {
        const pictures = cartItems.map((item) => {
            item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
            return item;
        });
        setCartItems(pictures);

        const floatPic = floatPictures.map((item) => {
            item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
            return item;
        });
        setFloatPictures(floatPic);

        const filteredFloatPic = filteredFloatPictures.map((item) => {
            item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
            return item;
        });
        setFilteredFloatPictures(filteredFloatPic);
    }, [borderPreference]);

    const isProductSlider = showing === 'allProducts' && currentSlider === 'products';
    const isFilteredProductsSlider = showing === 'allProducts' && currentSlider === 'filteredProducts';
    const isOrdersSlider = showing === 'orderList' && currentSlider === 'orders';

    useEffect(() => {
        setIsChangingState(true)
        setTimeout(() => {
            setIsChangingState(false)
            swiper?.update();
        }, 2000)
    }, [currentSlider]);

    const onSlideChange = (productId, productName, artistName) => {
        document.title = getProductMetaTitle(productName, artistName);
        setCanonicalUrl('product', productId);
    }

    return (isChangingState ? (
            <div className='ripple-container'>
                <div className='loadingio-spinner-ripple-jce4u9nidk'>
                    <div className='ldio-i8dcdsg1jeb'>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        ) : (
            <div className='slide-wrapper'>
                {(isProductSlider && floatPictures.length) ? (
                    <RecommendationsSlides
                        loadNewProduct={loadNewProduct}
                        onSlide={onSlideChange}
                        setSwiper={setSwiper}/>
                ) : (isFilteredProductsSlider && filteredFloatPictures.length) ? (
                    <ArtistSlides
                        loadNewProduct={loadNewProduct}
                        onSlide={onSlideChange}
                        setSwiper={setSwiper}/>
                ) : isOrdersSlider ? (
                    <CartSlides onSlide={onSlideChange}/>
                ) : <></>}

                {zoomedProduct ?
                    <ZoomImageModal/>
                    : <></>}
            </div>
        )
    );
};
export default Slides;

import appConfig from '../config.json';

/**
 * Utility function to get the meta title for a specific country.
 * @param {string} countryCode - The country code (e.g., 'US', 'GB').
 * @param {string} productName - The name of the product.
 * @param {string} artistName - The name of the artist.
 * @returns {string} - The formatted meta title or a default title if country or meta is not found.
 */
const country = JSON.parse(sessionStorage.getItem('country'));
const code = country || 'US';


export const getProductMetaTitle = (productName, artistName, countryCode = code) => {
    const countryConfig = appConfig.countries[countryCode];

    if (!countryConfig) {
        return appConfig.default.title;
    }

    let metaTitleTemplate = countryConfig.meta?.title?.product;

    if (metaTitleTemplate) {
        metaTitleTemplate = metaTitleTemplate
            .replace('{productName}', productName)
            .replace('{artistName}', artistName);
    }

    return metaTitleTemplate || appConfig.default.title;
};

export const getHomepageMetaTitle = (countryCode = code) => {
    const countryConfig = appConfig.countries[countryCode];

    if (!countryConfig) {
        return appConfig.default.title;
    }

    const homepageMetaTitle = countryConfig.meta?.title?.homepage;

    return homepageMetaTitle || appConfig.default.title;
};

export const appSubdomain = (countryCode = code) => {
    const countryConfig = appConfig.countries[countryCode];

    const subdomain = countryConfig.subdomain;

    return subdomain || appConfig.default.domain;
};

export const getCanonicalUrl = (type = 'splash', productId = '', countryCode = code) => {
    const countryConfig = appConfig.countries[countryCode];

    if (type === 'splash') {
        return countryConfig.subdomain
    } else if (type === 'product') {
        return `${countryConfig.subdomain}/product/${productId}`;
    }
    return appConfig.default.domain;
};

export const getCountry = (countryCode = code) => {
    return appConfig.countries[countryCode];
}

export const appCountries = appConfig.countries;

export const appApiUrl = appConfig.baseUrls[appConfig.environment];

export default appConfig;
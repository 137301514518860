import React, {Fragment} from "react";
import {useOrder} from "../../../contexts/OrderContext";
import SingleOrderItem from "../single-order-item";
import "./OrderList.View.scss";

const OrderList = () => {
    const {cartItems} = useOrder();

    // console.log("cartItems-=----", cartItems);

    return (
        <div className="order-list-tab-container">
            <Fragment>
                {cartItems.length ? (
                    cartItems.map((item) => {
                        return (
                            <SingleOrderItem
                                key={item?.id}
                                item={item}
                                cartItemsLength={cartItems?.length}
                            />
                        );
                    })
                ) : (
                    <h1 className="empty-order-text">Your order is currently empty.</h1>
                )}
            </Fragment>
        </div>
    );
};
export default OrderList;

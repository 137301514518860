import React from "react";
import "./PartialPrivacyPolicy.View.scss";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const PartialPrivacyPolicy = ({togglePrivacyPolicy}) => {
    return (
        <div className="body-wrapper">
            <div className="privacy-header-container">
                <h4 className="heading">
                    Privacy Policy of <strong>floatpic.com</strong>
                </h4>
                <p className="head-para">
                    This Website collects some Personal Data from its Users
                </p>
            </div>
            <h2 className="body-heading">
                Personal Data processed for the following purposes and using the
                following services:
            </h2>
            <div className="personal-data-wrapper">
                <Row>
                    <Col md={6}>
                        <div className="d-flex align-items-start single-content-wrapper">
                            <img src="/images/analytics.png"
                                 loading="lazy" alt=""/>
                            <div className="">
                                <h2 className="single-content-heading">Analytics</h2>
                                <h3 className="single-content-sub-heading">Google Analytics</h3>
                                <p className="single-content-para">
                                    Personal Data: Tracker; Usage Data
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex align-items-start single-content-wrapper">
                            <img src="/images/content.png"
                                 loading="lazy" alt=""/>
                            <div className="">
                                <h2 className="single-content-heading">
                                    Displaying content from external platforms
                                </h2>
                                <h3 className="single-content-sub-heading">Font Awesome</h3>
                                <p className="single-content-para">
                                    Personal Data: Tracker; Usage Data
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="d-flex align-items-start single-content-wrapper">
                            <img src="/images/content.png"
                                 loading="lazy" alt=""/>
                            <div className="">
                                <h2 className="single-content-heading">Handling payments</h2>
                                <h3 className="single-content-sub-heading">Stripe</h3>
                                <p className="single-content-para">
                                    Personal Data: billing address; email address; first name;
                                    last name; payment info; purchase history
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        {" "}
                        <div className="d-flex align-items-start single-content-wrapper">
                            <img src="/images/content.png"
                                 loading="lazy" alt=""/>
                            <div className="">
                                <h2 className="single-content-heading">Tag Management</h2>
                                <h3 className="single-content-sub-heading">
                                    Google Tag Manager
                                </h3>
                                <p className="single-content-para">
                                    Personal Data: Tracker; Usage Data
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="personal-data-wrapper">
                <h2 className="body-heading">
                    Further information about the processing of Personal Data
                </h2>
                <div className="d-flex align-items-start single-content-wrapper">
                    <img src="/images/content.png"
                         loading="lazy" alt=""/>
                    <div className="">
                        <h2 className="single-content-heading">
                            This policy is applicable to FloatPic Inc. worldwide sites:
                        </h2>
                        <p className="single-content-para mt-3">floatpic.com</p>
                        <p className="single-content-para">ca.floatpic.com</p>
                        <p className="single-content-para">uk.floatpic.com</p>
                        <p className="single-content-para">au.floatpic.com</p>
                    </div>
                </div>
            </div>

            <div className="personal-data-wrapper">
                <h2 className="body-heading">Contact information</h2>
                <div className="d-flex align-items-start single-content-wrapper">
                    <img src="/images/contact-person.png"
                         loading="lazy" alt=""/>
                    <div className="">
                        <h2 className="single-content-heading">
                            Owner and Data Controller
                        </h2>
                        <p className="single-content-para">FloatPic Inc</p>
                        <p className="single-content-para">4025 Dorchester Rd.</p>
                        <p className="single-content-para">Suite 336</p>
                        <p className="single-content-para">
                            Niagara Falls, Ontario L2E 7K8
                        </p>
                        <p className="single-content-para mb-3">Canada</p>
                        <p className="single-content-para">
                            These policies are applicable to FloatPic Inc.
                        </p>
                        <p className="single-content-para mb-3">worldwide sites:</p>
                        <p className="single-content-para">floatpic.com</p>
                        <p className="single-content-para">ca.floatpic.com</p>
                        <p className="single-content-para">uk.floatpic.com</p>
                        <p className="single-content-para mb-3">au.floatpic.com</p>
                        <p className="single-content-para">
                            <strong className="email-heading">Owner contact email:</strong>{" "}
                            admin@floatpic.com
                        </p>
                    </div>
                </div>
            </div>
            <div className="footer-wrapper">
                <p className="para-frist">Latest update: December 29, 2022</p>
                <p className="para-second">
                    <Link
                        to="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                        className="single-link"
                    >
                        iubenda
                    </Link>{" "}
                    hosts this content and only{" "}
                    <Link
                        to="https://www.iubenda.com/privacy-policy/65675001"
                        className="single-link"
                    >
                        collects the Personal Data strictly necessary
                    </Link>{" "}
                    for it to be provided.
                </p>
                <div className="d-flex justify-content-end">
                    <p className="para-frist single-link" onClick={togglePrivacyPolicy}>
                        Show the complete Privacy Policy
                    </p>
                </div>
            </div>
        </div>
    );
};
export default PartialPrivacyPolicy;

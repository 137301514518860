import React from 'react';
import {usePage} from '../../../../contexts/PageContext';
import {TransformComponent, TransformWrapper, useControls} from "react-zoom-pan-pinch";

const ZoomImageModal = () => {
    const {zoomedProduct, setZoomed, setZoomedProduct, setZoomedProductId, isTouchDevice} = usePage();

    const Controls = () => {
        const {zoomIn, zoomOut} = useControls();

        return (
            <div className="tools">
                <button onClick={() => zoomIn()}>+</button>
                <button onClick={() => zoomOut()}>-</button>
                {/*<button onClick={() => resetTransform()}>x</button>*/}
            </div>
        );
    };


    return (
        <div className='modal-overlay modal-overlay__zoom'
            // onClick={handleZoomToggle}
        >
            <div
                className='modal-content modal-content__zoom'
            >
                <TransformWrapper
                    className='relative'
                    doubleClick={{mode: 'toggle'}}
                    centerOnInit={true}
                    initialScale={isTouchDevice ? 3 : 2}
                    minScale={1}
                >
                    {(utils) => (
                        <React.Fragment>
                            <TransformComponent>
                                <img src={zoomedProduct?.img}
                                     alt={zoomedProduct?.art_id}
                                     className='w-100'
                                     loading="lazy"
                                />
                            </TransformComponent>
                            <Controls {...utils}/>
                        </React.Fragment>
                    )}
                </TransformWrapper>
            </div>
            <img onClick={() => {
                setZoomed(false);
                setZoomedProduct(null)
                setZoomedProductId(null)
            }} src="/images/zoom-close.png" alt="Close button" className="zoom-modal-close"/>
        </div>
    );
};

export default ZoomImageModal;

import React from 'react';
import {usePage} from '../../../contexts/PageContext';

const ZoomableImage = ({src, alt, initialHeight, onClickImage}) => {
    const {zoomed} = usePage();

    const imageStyles = {
        transition: 'visibility 0.2s',
        visibility: zoomed ? 'hidden' : 'visible',
    };

    return (
        <div
            className={`zoom-container ${zoomed ? 'zoomed' : ''}`}
            onClick={onClickImage}
        >
            <img
                src={src}
                alt={alt}
                draggable='false'
                className='zoom-image w-100' loading="lazy"
                style={{...imageStyles, height: initialHeight}}
            />
        </div>
    );
};

export default ZoomableImage;

import PropTypes from "prop-types";
import "./Button.scss";

const Button = ({
                    className = "",
                    transparent = false,
                    text = "button",
                    font500 = false,
                    ...props
                }) => {
    return (
        <button
            className={`primary-btn ${
                transparent ? "primary-btn-transparent" : "primary-btn-solid"
            } 
      ${font500 ? "font500" : ""}
       ${className}
      `}
            {...props}
        >
            {text}
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    transparent: PropTypes.bool,
    text: PropTypes.string,
};

export default Button;

const ButtonPlayPause = ({play, isPlaying}) => {
    return (
        <div className="btn-play-pause-container" onClick={play}>
            {isPlaying ? (
                <img
                    src="/images/audio-player/pause-icon.svg"
                    alt="pause"
                    className="pause-icon"
                    loading="lazy"
                />
            ) : (
                <img
                    src="/images/audio-player/play-icon.svg"
                    alt="play"
                    className="play-icon"
                    loading="lazy"
                />
            )}
        </div>

        // <div className="btn-play-pause-container" onClick={play}>
        //   <div className={isPlaying ? "btn-stop" : "btn-play"}></div>
        // </div>
    );
};
export {ButtonPlayPause};

const SingleAudioModalBtn = ({
                                 btnValue,
                                 src,
                                 onClick,
                                 speeds,
                                 speedControl,
                                 playBack,
                                 sleeps,
                                 sleepControl,
                                 sleepTime,
                             }) => {
    const filerSpeed =
        speeds?.length > 0 && speeds.filter((item) => item.id === speedControl);
    const filerSleep =
        sleeps?.length > 0 && sleeps.filter((item) => item.id === sleepControl);

    return (
        <div
            className="audio-modal-container d-flex align-items-center justify-content-center"
            onClick={onClick}
        >
            <img
                // src="/images/audio-player/speed-icon.svg"
                src={src}
                alt="speed-icon"
                className="icon"
                loading="lazy"
            />
            <p className="text">
                {btnValue}
                {playBack && <span> {filerSpeed[0]?.speed}</span>}
                {sleepTime && <span> {filerSleep[0]?.sleep}</span>}
            </p>
        </div>
    );
};
export {SingleAudioModalBtn};

import {Squash as Hamburger} from 'hamburger-react';
import React, {useEffect, useRef} from 'react';
import {useComboboxControls} from 'react-datalist-input';
// import { BiMenu } from "react-icons/bi";
import {usePage} from '../../../contexts/PageContext';
import {useOnClickOutside} from '../../../hooks/useOnClickOutside';
import {getCookie, retailAPI} from '../../../utils/common';
import CollapseHeader from '../collapse-header';
import './TopHeader.View.scss';

const TopHeader = () => {
    const {
        setShowing,
        showingRef,
        activeShopTab,
        setActiveShopTab,
        handleClickMenubarOpen,
        isMenubarOpen,
        setIsMenubarOpen,
        setFilterValue,
        reset
    } = usePage();

    const {setValue} = useComboboxControls({
        initialValue: '',
        isExpanded: false,
    });
    const ref = useRef();

    useOnClickOutside(ref, () => setIsMenubarOpen(false));

    const handleHomeClick = () => {
        setShowing((prev) => {
            showingRef.current = prev;
            return 'home';
        });
        setIsMenubarOpen(false);
        setActiveShopTab('SEARCH');

        if (activeShopTab === 'SEARCH') {
            setFilterValue('');
            setValue('');
        }

        window.history.replaceState(null, null, ' ');
        let post_data = {
            eventType: 'home-page-view',
            eventTime: new Date().toISOString(),
            userInfo: {
                userAgent: navigator.userAgent,
                ipAddress: '192.168.1.100',
            },
            visitorId: getCookie('_ga'),
            attributes: {
                global_website: {
                    text: [window.location.host],
                    indexable: true,
                    searchable: true,
                },
            },
        };
        retailAPI(post_data);
        reset();
    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!activeShopTab) setActiveShopTab('SEARCH');
    }, []);

    return (
        <div className={isMenubarOpen ? 'overlay' : ''}>
            <div
                className='header-container'
                ref={ref}
            >
                <div className='d-flex justify-content-center'>
                    <div
                        className={`d-flex justify-content-between header-sub-container ${
                            isMenubarOpen && 'white-bg-header'
                        }`}
                    >
                        <div className='logo'>
                            <img
                                src='/images/logo.png'
                                alt='float'
                                className='logo-main-img'
                                onClick={() => handleHomeClick()}
                                loading="lazy"
                            />
                        </div>
                        <div className='hamburger-menu'>
                            <Hamburger
                                size={25}
                                toggled={isMenubarOpen}
                                toggle={handleClickMenubarOpen}
                                // direction="not applicable"
                            />
                        </div>
                    </div>
                    <div
                        // ref={ref}
                        className={`menu-main-container ${
                            isMenubarOpen ? 'menu-main-container-open' : ''
                        } `}
                    >
                        <div className={`menu-inner-container`}>
                            <CollapseHeader/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default TopHeader;

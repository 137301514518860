import React, {Fragment, memo, useContext} from "react";
import {
    Accordion,
    AccordionContext,
    Card,
    Col,
    Row,
    useAccordionToggle,
} from "react-bootstrap";
import "./CompletePrivacyPolicy.View.scss";
import {MdArrowDropDown, MdArrowLeft} from "react-icons/md";
import Button from "../../ui/button/Button";

export function AccordionButtonToggle({children, eventKey, callback}) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            // style={{ backgroundColor: isCurrentEventKey ? "pink" : "lavender" }}
            onClick={decoratedOnClick}
            className={`toggle-button relative ${
                isCurrentEventKey ? "button-border" : ""
            }`}
        >
            {children}
            {isCurrentEventKey ? (
                <MdArrowDropDown size={25}/>
            ) : (
                <MdArrowLeft size={25}/>
            )}
        </button>
    );
}

const CompletePrivacyPolicy = memo(({
                                        togglePrivacyPolicy,
                                        acceptbtn,
                                        acceptCloseBtn,
                                        customHeightLocationCookie,
                                    }) => {
    return (
        <Fragment>
            <div
                className={`body-wrapper-complete ${
                    customHeightLocationCookie
                        ? "custom-height-location-cookie"
                        : "body-wrapper-cookie-height"
                }`}
            >
                <div className="privacy-header-container">
                    <h4 className="heading">
                        Privacy Policy of <strong>floatpic.com</strong>
                    </h4>
                    <p className="head-para">
                        This Website collects some Personal Data from its Users
                    </p>
                    <p className="head-para mt-3">
                        This document contains
                        <span className="single-link">
              a section dedicated to California consumers and their privacy
              rights.
            </span>
                    </p>
                    <p className="head-para">
                        This document contains
                        <span className="single-link">
              a section dedicated to Virginia consumers and their privacy
              rights.
            </span>
                    </p>
                    <p className="head-para">
                        This document can be printed for reference by using the print
                        command in the settings of any browser.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">Owner and Data Controller</h2>
                    <p className="single-content-para">FloatPic Inc</p>
                    <p className="single-content-para">4025 Dorchester Rd.</p>
                    <p className="single-content-para">Suite 336</p>
                    <p className="single-content-para">Niagara Falls, Ontario L2E 7K8</p>
                    <p className="single-content-para mb-3">Canada</p>
                    <p className="single-content-para">
                        These policies are applicable to FloatPic Inc.
                    </p>
                    <p className="single-content-para mb-3">worldwide sites:</p>
                    <p className="single-content-para">floatpic.com</p>
                    <p className="single-content-para">ca.floatpic.com</p>
                    <p className="single-content-para">uk.floatpic.com</p>
                    <p className="single-content-para mb-3">au.floatpic.com</p>
                    <p className="single-content-para">
                        <strong className="email-heading">Owner contact email:</strong>
                        admin@floatpic.com
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">Types of Data collected</h2>
                    <p className="single-content-para pb-3">
                        Among the types of Personal Data that this Website collects, by
                        itself or through third parties, there are: Usage Data; Tracker;
                        payment info; first name; last name; email address; billing address;
                        purchase history.
                    </p>
                    <p className="single-content-para">
                        Complete details on each type of Personal Data collected are
                        provided in the dedicated sections of this privacy policy or by
                        specific explanation texts displayed prior to the Data collection.{" "}
                        <br/>
                        Personal Data may be freely provided by the User, or, in case of
                        Usage Data, collected automatically when using this Website.
                    </p>
                    <p className="single-content-para">
                        Unless specified otherwise, all Data requested by this Website is
                        mandatory and failure to provide this Data may make it impossible
                        for this Website to provide its services. In cases where this
                        Website specifically states that some Data is not mandatory, Users
                        are free not to communicate this Data without consequences to the
                        availability or the functioning of the Service.
                    </p>
                    <p className="single-content-para pb-3">
                        Users who are uncertain about which Personal Data is mandatory are
                        welcome to contact the Owner. Any use of Cookies – or of other
                        tracking tools – by this Website or by the owners of third-party
                        services used by this Website serves the purpose of providing the
                        Service required by the User, in addition to any other purposes
                        described in the present document and in the Cookie Policy, if
                        available.
                    </p>
                    <p className="single-content-para">
                        Users are responsible for any third-party Personal Data obtained,
                        published or shared through this Website and confirm that they have
                        the third party's consent to provide the Data to the Owner.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Mode and place of processing the Data
                    </h2>
                    <h2 className="single-content-sub-heading pt-3">
                        Methods of processing
                    </h2>
                    <p className="single-content-para pb-3">
                        The Owner takes appropriate security measures to prevent
                        unauthorized access, disclosure, modification, or unauthorized
                        destruction of the Data. <br/> The Data processing is carried out
                        using computers and/or IT enabled tools, following organizational
                        procedures and modes strictly related to the purposes indicated. In
                        addition to the Owner, in some cases, the Data may be accessible to
                        certain types of persons in charge, involved with the operation of
                        this Website (administration, sales, marketing, legal, system
                        administration) or external parties (such as third-party technical
                        service providers, mail carriers, hosting providers, IT companies,
                        communications agencies) appointed, if necessary, as Data Processors
                        by the Owner. The updated list of these parties may be requested
                        from the Owner at any time.
                    </p>
                    <h2 className="single-content-sub-heading pt-3">
                        Legal basis of processing
                    </h2>
                    <p className="single-content-para pb-3">
                        The Owner may process Personal Data relating to Users if one of the
                        following applies:
                    </p>
                    <ul>
                        <li>
                            Users have given their consent for one or more specific purposes.
                            Note: Under some legislations the Owner may be allowed to process
                            Personal Data until the User objects to such processing
                            (“opt-out”), without having to rely on consent or any other of the
                            following legal bases. This, however, does not apply, whenever the
                            processing of Personal Data is subject to European data protection
                            law;
                        </li>
                        <li>
                            provision of Data is necessary for the performance of an agreement
                            with the User and/or for any pre-contractual obligations thereof;
                        </li>
                        <li>
                            processing is necessary for compliance with a legal obligation to
                            which the Owner is subject;
                        </li>
                        <li>
                            processing is related to a task that is carried out in the public
                            interest or in the exercise of official authority vested in the
                            Owner;
                        </li>
                        <li>
                            processing is necessary for the purposes of the legitimate
                            interests pursued by the Owner or by a third party.
                        </li>
                    </ul>
                    <p className="single-content-para">
                        In any case, the Owner will gladly help to clarify the specific
                        legal basis that applies to the processing, and in particular
                        whether the provision of Personal Data is a statutory or contractual
                        requirement, or a requirement necessary to enter into a contract.
                    </p>
                    <h2 className="single-content-sub-heading pt-3">Place</h2>
                    <p className="single-content-para pb-3">
                        The Data is processed at the Owner's operating offices and in any
                        other places where the parties involved in the processing are
                        located
                    </p>
                    <p className="single-content-para pb-3">
                        Depending on the User's location, data transfers may involve
                        transferring the User's Data to a country other than their own. To
                        find out more about the place of processing of such transferred
                        Data, Users can check the section containing details about the
                        processing of Personal Data.
                    </p>
                    <p className="single-content-para pb-3">
                        Users are also entitled to learn about the legal basis of Data
                        transfers to a country outside the European Union or to any
                        international organization governed by public international law or
                        set up by two or more countries, such as the UN, and about the
                        security measures taken by the Owner to safeguard their Data.
                    </p>
                    <p className="single-content-para">
                        If any such transfer takes place, Users can find out more by
                        checking the relevant sections of this document or inquire with the
                        Owner using the information provided in the contact section.
                    </p>
                    <h2 className="single-content-sub-heading pt-3">Retention time</h2>
                    <p className="single-content-para pb-3">
                        Personal Data shall be processed and stored for as long as required
                        by the purpose they have been collected for.
                    </p>
                    <p className="single-content-para pb-2">Therefore:</p>
                    <ul>
                        <li>
                            Personal Data collected for purposes related to the performance of
                            a contract between the Owner and the User shall be retained until
                            such contract has been fully performed.
                        </li>
                        <li>
                            Personal Data collected for the purposes of the Owner’s legitimate
                            interests shall be retained as long as needed to fulfill such
                            purposes. Users may find specific information regarding the
                            legitimate interests pursued by the Owner within the relevant
                            sections of this document or by contacting the Owner.
                        </li>
                    </ul>
                    <p className="single-content-para pb-3">
                        The Owner may be allowed to retain Personal Data for a longer period
                        whenever the User has given consent to such processing, as long as
                        such consent is not withdrawn. Furthermore, the Owner may be obliged
                        to retain Personal Data for a longer period whenever required to do
                        so for the performance of a legal obligation or upon order of an
                        authority.
                    </p>
                    <p className="single-content-para">
                        Once the retention period expires, Personal Data shall be deleted.
                        Therefore, the right of access, the right to erasure, the right to
                        rectification and the right to data portability cannot be enforced
                        after expiration of the retention period.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">The purposes of processing</h2>
                    <p className="single-content-para pb-3">
                        The Data concerning the User is collected to allow the Owner to
                        provide its Service, comply with its legal obligations, respond to
                        enforcement requests, protect its rights and interests (or those of
                        its Users or third parties), detect any malicious or fraudulent
                        activity, as well as the following: Displaying content from external
                        platforms, Analytics, Tag Management and Handling payments.
                    </p>
                    <p className="single-content-para">
                        For specific information about the Personal Data used for each
                        purpose, the User may refer to the section “Detailed information on
                        the processing of Personal Data”.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Detailed information on the processing of Personal Data
                    </h2>
                    <p className="single-content-para pb-3">
                        Personal Data is collected for the following purposes and using the
                        following services:
                    </p>
                    <Row>
                        <Col md={6} className="pb-3">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container">
                                                <img src="/images/content.png" alt=""
                                                     loading="lazy"/>
                                                <h2 className="single-text-accordion">Analytics</h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <p className="single-content-para pb-3">
                                                The services contained in this section enable the Owner
                                                to monitor and analyze web traffic and can be used to
                                                keep track of User behavior.
                                            </p>
                                            <h2 className="single-content-sub-heading pb-3">
                                                Google Analytics (Google LLC)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Google Analytics is a web analysis service provided by
                                                Google LLC (“Google”). Google utilizes the Data
                                                collected to track and examine the use of this Website,
                                                to prepare reports on its activities and share them with
                                                other Google services. <br/> Google may use the Data
                                                collected to contextualize and personalize the ads of
                                                its own advertising network.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Personal Data processed: Tracker; Usage Data.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Place of processing: United States – Privacy Policy –
                                                Opt Out.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Category of personal information collected according to
                                                the CCPA: internet information.
                                            </p>
                                            <p className="single-content-para">
                                                This processing constitutes:
                                            </p>
                                            <ul>
                                                <li>a sale according to the CCPA and the VCDPA</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col md={6} className="pb-3">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container">
                                                <img src="/images/content.png" alt=""
                                                     loading="lazy"/>
                                                <h2 className="single-text-accordion">
                                                    Displaying content from external platforms
                                                </h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <p className="single-content-para pb-3">
                                                This type of service allows you to view content hosted
                                                on external platforms directly from the pages of this
                                                Website and interact with them. <br/> This type of
                                                service might still collect web traffic data for the
                                                pages where the service is installed, even when Users do
                                                not use it.
                                            </p>
                                            <h2 className="single-content-sub-heading pb-3">
                                                Font Awesome (Fonticons, Inc. )
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Font Awesome is a typeface visualization service
                                                provided by Fonticons, Inc. that allows this Website to
                                                incorporate content of this kind on its pages.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Personal Data processed: Tracker; Usage Data.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Place of processing: United States – Privacy Policy –
                                                Opt Out.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Category of personal information collected according to
                                                the CCPA: internet information.
                                            </p>
                                            <p className="single-content-para">
                                                This processing constitutes:
                                            </p>
                                            <ul>
                                                <li>a sale according to the CCPA and the VCDPA</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col md={6} className="pb-3">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container">
                                                <img src="/images/content.png"
                                                     loading="lazy" alt=""/>
                                                <h2 className="single-text-accordion">
                                                    Handling payments
                                                </h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <p className="single-content-para pb-3">
                                                Unless otherwise specified, this Website processes any
                                                payments by credit card, bank transfer or other means
                                                via external payment service providers. In general and
                                                unless where otherwise stated, Users are requested to
                                                provide their payment details and personal information
                                                directly to such payment service providers. This Website
                                                isn't involved in the collection and processing of such
                                                information: instead, it will only receive a
                                                notification by the relevant payment service provider as
                                                to whether payment has been successfully completed.
                                            </p>
                                            <h2 className="single-content-sub-heading pb-3">
                                                Stripe
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Stripe is a payment service provided by Stripe Inc or by
                                                Stripe Payments Ltd, depending on how the Owner manages
                                                the Data processing.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Personal Data processed: billing address; email address;
                                                first name; last name; payment info; purchase history.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Place of processing: United States – Privacy Policy –
                                                Opt Out.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Category of personal information collected according to
                                                the CCPA: internet information.
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                        <Col md={6} className="pb-3">
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container">
                                                <img src="/images/content.png"
                                                     loading="lazy" alt=""/>
                                                <h2 className="single-text-accordion">
                                                    Tag Management
                                                </h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <p className="single-content-para pb-3">
                                                This type of service helps the Owner to manage the tags
                                                or scripts needed on this Website in a centralized
                                                fashion.
                                                <br/>
                                                This results in the Users' Data flowing through these
                                                services, potentially resulting in the retention of this
                                                Data.
                                            </p>
                                            <h2 className="single-content-sub-heading pb-3">
                                                Google Tag Manager (Google LLC)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Google Tag Manager is a tag management service provided
                                                by Google LLC.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Personal Data processed: Tracker; Usage Data.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Place of processing: United States – Privacy Policy –
                                                Opt Out.
                                            </p>
                                            <p className="single-content-para pb-3">
                                                Category of personal information collected according to
                                                the CCPA: internet information.
                                            </p>
                                            <p className="single-content-para">
                                                This processing constitutes:
                                            </p>
                                            <ul>
                                                <li>a sale according to the CCPA and the VCDPA</li>
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Further information about the processing of Personal Data
                    </h2>
                    <Row>
                        <Col lg={6}>
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container">
                                                <img src="/images/content.png"
                                                     loading="lazy" alt=""/>
                                                <h2 className="single-text-accordion">
                                                    This policy is applicable to FloatPic Inc. worldwide
                                                    sites:
                                                </h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <p className="single-content-para">floatpic.com</p>
                                            <p className="single-content-para">ca.floatpic.com</p>
                                            <p className="single-content-para">uk.floatpic.com</p>
                                            <p className="single-content-para">au.floatpic.com</p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">The rights of Users</h2>
                    <p className="single-content-para pb-3">
                        Users may exercise certain rights regarding their Data processed by
                        the Owner.
                    </p>
                    <p className="single-content-para pb-2">
                        In particular, Users have the right to do the following:
                    </p>
                    <ul>
                        <li>
                            <strong>Withdraw their consent at any time.</strong> Users have
                            the right to withdraw consent where they have previously given
                            their consent to the processing of their Personal Data.
                        </li>
                        <li>
                            <strong>Object to processing of their Data.</strong> Users have
                            the right to object to the processing of their Data if the
                            processing is carried out on a legal basis other than consent.
                            Further details are provided in the dedicated section below.
                        </li>
                        <li>
                            <strong>Access their Data.</strong> Users have the right to learn
                            if Data is being processed by the Owner, obtain disclosure
                            regarding certain aspects of the processing and obtain a copy of
                            the Data undergoing processing.
                        </li>
                        <li>
                            <strong>Verify and seek rectification.</strong> Users have the
                            right to verify the accuracy of their Data and ask for it to be
                            updated or corrected.
                        </li>
                        <li>
                            <strong>Restrict the processing of their Data.</strong> Users have
                            the right, under certain circumstances, to restrict the processing
                            of their Data. In this case, the Owner will not process their Data
                            for any purpose other than storing it.
                        </li>
                        <li>
                            <strong>
                                Have their Personal Data deleted or otherwise removed.
                            </strong>
                            Users have the right, under certain circumstances, to obtain the
                            erasure of their Data from the Owner.
                        </li>
                        <li>
                            <strong>
                                Receive their Data and have it transferred to another
                                controller.
                            </strong>
                            Users have the right to receive their Data in a structured,
                            commonly used and machine readable format and, if technically
                            feasible, to have it transmitted to another controller without any
                            hindrance. This provision is applicable provided that the Data is
                            processed by automated means and that the processing is based on
                            the User's consent, on a contract which the User is part of or on
                            pre-contractual obligations thereof.
                        </li>
                        <li>
                            <strong>Lodge a complaint.</strong> Users have the right to bring
                            a claim before their competent data protection authority.
                        </li>
                    </ul>
                    <h2 className="single-content-sub-heading pt-3">
                        Details about the right to object to processing
                    </h2>
                    <p className="single-content-para pb-3">
                        Where Personal Data is processed for a public interest, in the
                        exercise of an official authority vested in the Owner or for the
                        purposes of the legitimate interests pursued by the Owner, Users may
                        object to such processing by providing a ground related to their
                        particular situation to justify the objection.
                    </p>
                    <p className="single-content-para">
                        Users must know that, however, should their Personal Data be
                        processed for direct marketing purposes, they can object to that
                        processing at any time without providing any justification. To
                        learn, whether the Owner is processing Personal Data for direct
                        marketing purposes, Users may refer to the relevant sections of this
                        document.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        How to exercise these rights
                    </h2>
                    <p className="single-content-para">
                        Any requests to exercise User rights can be directed to the Owner
                        through the contact details provided in this document. These
                        requests can be exercised free of charge and will be addressed by
                        the Owner as early as possible and always within one month.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">Cookie Policy</h2>
                    <p className="single-content-para">
                        This Website uses Trackers. To learn more, the User may consult the
                        <span className="single-link"> Cookie Policy.</span>
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Additional information about Data collection and processing
                    </h2>
                    <h2 className="single-content-sub-heading pt-4">Legal action</h2>
                    <p className="single-content-para">
                        The User's Personal Data may be used for legal purposes by the Owner
                        in Court or in the stages leading to possible legal action arising
                        from improper use of this Website or the related Services. <br/>{" "}
                        The User declares to be aware that the Owner may be required to
                        reveal personal data upon request of public authorities.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        Additional information about User's Personal Data
                    </h2>
                    <p className="single-content-para">
                        In addition to the information contained in this privacy policy,
                        this Website may provide the User with additional and contextual
                        information concerning particular Services or the collection and
                        processing of Personal Data upon request.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        System logs and maintenance
                    </h2>
                    <p className="single-content-para">
                        For operation and maintenance purposes, this Website and any
                        third-party services may collect files that record interaction with
                        this Website (System logs) or use other Personal Data (such as the
                        IP Address) for this purpose.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        Information not contained in this policy
                    </h2>
                    <p className="single-content-para">
                        More details concerning the collection or processing of Personal
                        Data may be requested from the Owner at any time. Please see the
                        contact information at the beginning of this document.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        How “Do Not Track” requests are handled
                    </h2>
                    <p className="single-content-para">
                        This Website does not support “Do Not Track” requests. <br/> To
                        determine whether any of the third-party services it uses honor the
                        “Do Not Track” requests, please read their privacy policies.
                    </p>
                    <h2 className="single-content-sub-heading pt-4">
                        Changes to this privacy policy
                    </h2>
                    <p className="single-content-para">
                        The Owner reserves the right to make changes to this privacy policy
                        at any time by notifying its Users on this page and possibly within
                        this Website and/or - as far as technically and legally feasible -
                        sending a notice to Users via any contact information available to
                        the Owner. It is strongly recommended to check this page often,
                        referring to the date of the last modification listed at the bottom.
                    </p>
                    <p className="single-content-para pt-3">
                        Should the changes affect processing activities performed on the
                        basis of the User’s consent, the Owner shall collect new consent
                        from the User, where required.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Information for Californian consumers
                    </h2>
                    <p className="single-content-para">
                        This part of the document integrates with and supplements the
                        information contained in the rest of the privacy policy and is
                        provided by the business running this Website and, if the case may
                        be, its parent, subsidiaries and affiliates (for the purposes of
                        this section referred to collectively as “we”, “us”, “our”).
                    </p>
                    <p className="single-content-para pt-3">
                        The provisions contained in this section apply to all Users (Users
                        are referred to below, simply as “you”, “your”, “yours”), who are
                        consumers residing in the state of California, United States of
                        America, according to the "California Consumer Privacy Act of 2018"
                        (the "CCPA"), as updated by the "California Privacy Rights Act" (the
                        "CPRA") and subsequent regulations. For such consumers, these
                        provisions supersede any other possibly divergent or conflicting
                        provisions contained in the privacy policy.
                    </p>
                    <p className="single-content-para pt-3 pb-4">
                        This part of the document uses the terms “personal information” (and
                        “sensitive personal information”) as defined in the California
                        Consumer Privacy Act (CCPA)
                    </p>
                    <h2 className="single-content-sub-heading pt-0">
                        Notice at collection
                    </h2>
                    <h2 className="single-content-sub-heading sub-heading-cat pt-3">
                        Categories of personal information collected, used, sold, or shared
                    </h2>
                    <p className="single-content-para pt-3 pb-3">
                        In this section we summarize the categories of personal information
                        that we've collected, used, sold, or shared and the purposes
                        thereof.{" "}
                        <strong className="sub-heading-cat">
                            You can read about these activities in detail in the section
                            titled “Detailed information on the processing of Personal Data”
                            within this document.
                        </strong>
                    </p>
                    <h2 className="single-content-sub-heading sub-heading-cat pb-3">
                        Information we collect: the categories of personal information we
                        collect
                    </h2>
                    <p className="single-content-para pb-3">
                        We have collected the following categories of personal information
                        about you: identifiers, commercial information and internet
                        information.
                    </p>
                    <p className="single-content-para pb-3">
                        We have collected the following categories of sensitive personal
                        information: payment info
                    </p>
                    <p className="single-content-para pb-3">
                        We will not collect additional categories of personal information
                        without notifying you.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        Your right to limit the use or disclosure of your sensitive personal
                        information and how you can exercise it
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to request that we limit the use or disclosure of
                        your sensitive personal information to only that which is necessary
                        to perform the services or provide the goods, as is reasonably
                        expected by an average consumer.
                    </p>
                    <p className="single-content-para pb-3">
                        We can also use your sensitive personal information to perform
                        specific purposes set forth by the law (such as, including but not
                        limited to, helping to ensure security and integrity; undertaking
                        activities to verify or maintain the quality or safety of our
                        service) and as authorized by the relevant regulations.
                    </p>
                    <p className="single-content-para pb-3">
                        Outside of the aforementioned specific purposes, you have the right
                        to freely request, at any time, that we do not use or disclose your
                        sensitive personal information. This means that whenever you ask us
                        to stop using your sensitive personal information, we will abide by
                        your request and we will instruct our service providers and
                        contractors to do the same.
                    </p>
                    <p className="single-content-para pb-3">
                        To fully exercise your right to limit the use or disclosure of your
                        sensitive personal information you can contact us at any time, using
                        the contact details provided in this document.
                    </p>
                    <p className="single-content-para pb-3">
                        For a simplified opt-out method you can also use the privacy choices
                        link provided on this Website.
                    </p>
                    <p className="single-content-para pb-3">
                        We use any personal information collected from you in connection
                        with the submission of your request solely for the purposes of
                        complying with the request.
                    </p>
                    <p className="single-content-para pb-3">
                        Once you have exercised this right, we are required to wait at least
                        12 months before asking whether you have changed your mind..
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        What are the purposes for which we use your personal information?
                    </h2>
                    <p className="single-content-para pb-3">
                        We may use your personal information to allow the operational
                        functioning of this Website and features thereof (“business
                        purposes”). In such cases, your personal information will be
                        processed in a fashion necessary and proportionate to the business
                        purpose for which it was collected, and strictly within the limits
                        of compatible operational purposes.
                    </p>
                    <p className="single-content-para pb-3">
                        We may also use your personal information for other reasons such as
                        for commercial purposes (as indicated within the section “Detailed
                        information on the processing of Personal Data” within this
                        document), as well as for complying with the law and defending our
                        rights before the competent authorities where our rights and
                        interests are threatened or we suffer an actual damage.
                    </p>
                    <p className="single-content-para pb-3">
                        We won’t process your information for unexpected purposes, or for
                        purposes incompatible with the purposes originally disclosed,
                        without your consent.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        How long do we keep your personal information?
                    </h2>
                    <p className="single-content-para pb-3">
                        Unless stated otherwise inside the “Detailed information on the
                        processing of Personal Data” section, we will not retain your
                        personal information for longer than is reasonably necessary for the
                        purpose(s) they have been collected for.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        How we collect information: what are the sources of the personal
                        information we collect?
                    </h2>
                    <p className="single-content-para pb-3">
                        We collect the above-mentioned categories of personal information,
                        either directly or indirectly, from you when you use this Website.
                    </p>
                    <p className="single-content-para pb-3">
                        For example, you directly provide your personal information when you
                        submit requests via any forms on this Website. You also provide
                        personal information indirectly when you navigate this Website, as
                        personal information about you is automatically observed and
                        collected.
                    </p>
                    <p className="single-content-para pb-3">
                        Finally, we may collect your personal information from third parties
                        that work with us in connection with the Service or with the
                        functioning of this Application and features thereof.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        How we use the information we collect: disclosing of your personal
                        information with third parties for a business purpose
                    </h2>
                    <p className="single-content-para pb-3">
                        For our purposes, the word “third party” means a person who is not
                        any of the following: a service provider or a contractor, as defined
                        by the CCPA.
                    </p>
                    <p className="single-content-para pb-3">
                        We disclose your personal information with the third parties listed
                        in detail in the section titled “Detailed information on the
                        processing of Personal Data” within this document. These third
                        parties are grouped and categorized in accordance with the different
                        purposes of processing.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        Sale or sharing of your personal information
                    </h2>
                    <p className="single-content-para pb-3">
                        For our purposes, the word “sale” means any “selling, renting,
                        releasing, disclosing, disseminating, making available, transferring
                        or otherwise communicating orally, in writing, or by electronic
                        means, a consumer's personal information by the business to
                        <strong>
                            {" "}
                            a third party, for monetary or other valuable consideration”,{" "}
                        </strong>
                        as defined by the CCPA.
                    </p>
                    <p className="single-content-para pb-3">
                        This means that, for example, a sale can happen whenever an
                        application runs ads, or makes statistical analyses on the traffic
                        or views, or simply because it uses tools such as social network
                        plugins and the like.
                    </p>
                    <p className="single-content-para pb-3">
                        For our purposes, the word “sharing” means any “sharing, renting,
                        releasing, disclosing, disseminating, making available,
                        transferring, or otherwise communicating orally, in writing, or by
                        electronic or other means, a consumer's personal information by the
                        business to a third party for cross-context behavioral advertising,
                        whether or not for monetary or other valuable consideration,
                        including transactions between a business and a third party for
                        cross-context behavioral advertising for the benefit of a business
                        in which no money is exchanged”, as defined by the CCPA. <br/>{" "}
                        Please note that the exchange of personal information with a service
                        provider pursuant to a written contract that meets the requirements
                        set by the CCPA, does not constitute a sale or sharing of your
                        personal information.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        Your right to opt out of the sale or sharing of your personal
                        information and how you can exercise it
                    </h2>
                    <p className="single-content-para pb-3">
                        We sell or share your personal information with the third parties
                        <strong>
                            {" "}
                            listed in detail in the section titled “Detailed information on
                            the processing of Personal Data” within this document.
                        </strong>{" "}
                        These third parties are grouped and categorized in accordance with
                        the different purposes of processing.
                    </p>
                    <p className="single-content-para pb-3">
                        You have the right to opt out of the sale or sharing of your
                        personal information. This means that whenever you request us to
                        stop selling or sharing your personal information, we will abide by
                        your request.
                        <br/>
                        Such requests can be made freely, at any time, without submitting
                        any verifiable request. <br/> To fully exercise your right to opt
                        out, you can contact us at any time using the contact details
                        provided in this document. <br/> For a simplified opt-out method
                        you can also use the privacy choices link provided on this Website.
                    </p>
                    <p className="single-content-para pb-3">
                        If you want to submit requests to opt out of the sale or sharing of
                        personal information via a user-enabled global privacy control, like
                        the Global Privacy Control (“GPC”), you are free to do so and we
                        will abide by such request. The GPC consists of a setting or
                        extension in the browser or mobile device and acts as a mechanism
                        that websites can use to indicate they support the GPC signal. If
                        you want to use GPC, you can download and enable it via a
                        participating browser or browser extension. More information about
                        downloading GPC is available here.
                    </p>
                    <p className="single-content-para pb-3">
                        We use any personal information collected from you in connection
                        with the submission of your opt-out request solely for the purposes
                        of complying with the opt-out request.
                    </p>
                    <p className="single-content-para pb-3">
                        Once you have opted out, we are required to wait at least 12 months
                        before asking whether you have changed your mind.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        Your privacy rights under the California Consumer Privacy Act and
                        how to exercise them
                    </h2>
                    <h2 className="single-content-sub-heading sub-heading-cat pb-3">
                        The right to access personal information: the right to know and to
                        portability
                    </h2>
                    <p className="single-content-para pb-2">
                        You have the right to request that we disclose to you:
                    </p>
                    <ul>
                        <li>
                            the categories of personal information that we collect about you;
                        </li>
                        <li>
                            the sources from which the personal information is collected;
                        </li>
                        <li>the purposes for which we use your information;</li>
                        <li>to whom we disclose such information;</li>
                        <li>
                            the specific pieces of personal information we have collected
                            about you.
                        </li>
                    </ul>
                    <p className="single-content-para sub-heading-cat pb-2">
                        You also have{" "}
                        <strong>
                            the right to know what personal information is sold or shared and
                            to whom.
                        </strong>{" "}
                        In particular, you have the right to request two separate lists from
                        us where we disclose:
                    </p>
                    <ul>
                        <li>
                            the categories of personal information that we sold or shared
                            about you and the categories of third parties to whom the personal
                            information was sold or shared;{" "}
                        </li>
                        <li>
                            the categories of personal information that we disclosed about you
                            for a business purpose and the categories of persons to whom it
                            was disclosed for a business purpose.
                        </li>
                    </ul>
                    <p className="single-content-para pb-3">
                        The disclosure described above will be limited to the personal
                        information collected or used over the past 12 months.
                    </p>
                    <p className="single-content-para pb-3">
                        If we deliver our response electronically, the information enclosed
                        will be "portable", i.e. delivered in an easily usable format to
                        enable you to transmit the information to another entity without
                        hindrance – provided that this is technically feasible.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        The right to request the deletion of your personal information
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to request that we delete any of your personal
                        information, subject to exceptions set forth by the law (such as,
                        including but not limited to, where the information is used to
                        identify and repair errors on this Website, to detect security
                        incidents and protect against fraudulent or illegal activities, to
                        exercise certain rights etc.)
                    </p>
                    <p className="single-content-para pb-3">
                        If no legal exception applies, as a result of exercising your right,
                        we will delete your personal information and notify any of our
                        service providers and all third parties to whom we have sold or
                        shared the personal information to do so – provided that this is
                        technically feasible and doesn’t involve disproportionate effor
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        The right to correct inaccurate personal information
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to request that we correct any inaccurate
                        personal information we maintain about you, taking into account the
                        nature of the personal information and the purposes of the
                        processing of the personal information
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        The right to opt out of sale or sharing of personal information and
                        to limit the use of your sensitive personal information
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to opt out of the sale or sharing of your
                        personal information. You also have the right to request that we
                        limit our use or disclosure of your sensitive personal information.
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        The right of no retaliation following opt-out or exercise of other
                        rights (the right to non-discrimination)
                    </h2>
                    <p className="single-content-para pb-3">
                        We will not discriminate against you for exercising your rights
                        under the CCPA. This means that we will not discriminate against
                        you, including, but not limited to, by denying goods or services,
                        charging you a different price, or providing a different level or
                        quality of goods or services just because you exercised your
                        consumer privacy rights.
                    </p>
                    <p className="single-content-para pb-3">
                        However, if you refuse to provide your personal information to us or
                        ask us to delete or stop selling your personal information, and that
                        personal information or sale is necessary for us to provide you with
                        goods or services, we may not be able to complete that transaction.
                    </p>
                    <p className="single-content-para pb-3">
                        To the extent permitted by the law, we may offer you promotions,
                        discounts, and other deals in exchange for collecting, keeping, or
                        selling your personal information, provided that the financial
                        incentive offered is reasonably related to the value of your
                        personal information.
                    </p>

                    <h2 className="single-content-sub-heading pb-3">
                        How to exercise your rights
                    </h2>
                    <p className="single-content-para pb-3">
                        To exercise the rights described above, you need to submit your
                        verifiable request to us by contacting us via the details provided
                        in this document.
                    </p>
                    <p className="single-content-para pb-3">
                        For us to respond to your request, it’s necessary that we know who
                        you are. Therefore, you can only exercise the above rights by making
                        a verifiable request which must:
                    </p>
                    <ul>
                        <li>
                            provide sufficient information that allows us to reasonably verify
                            you are the person about whom we collected personal information or
                            an authorized representative;
                        </li>
                        <li>
                            describe your request with sufficient detail that allows us to
                            properly understand, evaluate, and respond to it.
                        </li>
                    </ul>
                    <p className="single-content-para pb-3">
                        We will not respond to any request if we are unable to verify your
                        identity and therefore confirm the personal information in our
                        possession actually relates to you.
                    </p>
                    <p className="single-content-para pb-3">
                        Making a verifiable consumer request does not require you to create
                        an account with us. We will use any personal information collected
                        from you in connection with the verification of your request solely
                        for the purposes of verification and shall not further disclose the
                        personal information, retain it longer than necessary for purposes
                        of verification, or use it for unrelated purposes
                    </p>
                    <p className="single-content-para pb-3">
                        If you cannot personally submit a verifiable request, you can
                        authorize a person registered with the California Secretary of State
                        to act on your behalf.
                    </p>
                    <p className="single-content-para pb-3">
                        If you are an adult, you can make a verifiable request on behalf of
                        a minor under your parental authority.
                    </p>
                    <p className="single-content-para pb-3">
                        You can submit a maximum number of 2 requests over a period of 12
                        months
                    </p>
                    <h2 className="single-content-sub-heading pb-3">
                        How and when we are expected to handle your request
                    </h2>
                    <p className="single-content-para pb-3">
                        We will confirm receipt of your verifiable request within 10 days
                        and provide information about how we will process your request.
                    </p>
                    <p className="single-content-para pb-3">
                        We will respond to your request within 45 days of its receipt.
                        Should we need more time, we will explain to you the reasons why,
                        and how much more time we need. In this regard, please note that we
                        may take up to 90 days to fulfill your request.
                    </p>
                    <p className="single-content-para pb-3">
                        Our disclosure(s) will cover the preceding 12-month period. Only
                        with regard to personal information collected on or after January 1,
                        2022, you have the right to request that we disclose information
                        beyond the 12-month period, and we will provide them to you unless
                        doing so proves impossible or would involve a disproportionate
                        effort.
                    </p>
                    <p className="single-content-para pb-3">
                        Should we deny your request, we will explain you the reasons behind
                        our denial.
                    </p>
                    <p className="single-content-para">
                        We do not charge a fee to process or respond to your verifiable
                        request unless such request is manifestly unfounded or excessive. In
                        such cases, we may charge a reasonable fee, or refuse to act on the
                        request. In either case, we will communicate our choices and explain
                        the reasons behind it.
                    </p>
                </div>
                <div className="content-wrapper">
                    <h2 className="single-content-heading">
                        Information for Virginia consumers
                    </h2>
                    <p className="single-content-para pb-3">
                        This part of the document integrates with and supplements the
                        information contained in the rest of the privacy policy and is
                        provided by the controller running this Application and, if the case
                        may be, its parent, subsidiaries and affiliates (for the purposes of
                        this section referred to collectively as “we”, “us”, “our”).
                    </p>
                    <p className="single-content-para pb-3">
                        The provisions contained in this section apply to all Users (Users
                        are referred to below, simply as “you”, “your”, “yours”), who are
                        consumers residing in the Commonwealth of Virginia, according to the
                        “Virginia Consumer Data Protection Act" (the "VCDPA"), and, for such
                        consumers, these provisions supersede any other possibly divergent
                        or conflicting provisions contained in the privacy policy.
                    </p>
                    <p className="single-content-para pb-3">
                        This part of the document uses the term “personal data” as defined
                        in the VCDPA.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Categories of personal data processed
                    </h2>
                    <p className="single-content-para pb-3">
                        In this section, we summarize the categories of personal data that
                        we've processed and the purposes thereof. You can read about these
                        activities in detail in the section titled “Detailed information on
                        the processing of Persona Data” within this document.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Categories of personal data we collect
                    </h2>
                    <p className="single-content-para pb-3">
                        We have collected the following categories of personal data:
                        identifiers, commercial information and internet information
                    </p>
                    <p className="single-content-para pb-3">
                        We do not collect sensitive data.
                    </p>
                    <p className="single-content-para pb-3">
                        We will not collect additional categories of personal data without
                        notifying you.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Why we process your personal data
                    </h2>
                    <p className="single-content-para pb-3">
                        To find out why we process your personal data, you can read the
                        sections titled “Detailed information on the processing of Personal
                        Data” and “The purposes of processing” within this document.
                    </p>
                    <p className="single-content-para pb-3">
                        We won’t process your information for unexpected purposes, or for
                        purposes incompatible with the purposes originally disclosed,
                        without your consent. <br/> You can freely give, deny, or withdraw
                        such consent at any time using the contact details provided in this
                        document.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        How we use the data we collect: sharing of your personal data with
                        third parties
                    </h2>
                    <p className="single-content-para pb-3">
                        We share your personal data with the third parties listed in detail
                        in the section titled “Detailed information on the processing of
                        Personal Data” within this document. These third parties are grouped
                        and categorized in accordance with the different purposes of
                        processing. <br/>
                        For our purposes, the word "third party" means "a natural or legal
                        person, public authority, agency, or body other than the consumer,
                        controller, processor, or an affiliate of the processor or the
                        controller" as defined by the VCDPA.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Sale of your personal data
                    </h2>
                    <p className="single-content-para pb-3">
                        For our purposes, the word “sale” means any “exchange of personal
                        data for monetary consideration by us to a third party“ as defined
                        by the VCDPA. <br/> Please note that according to the VCDPA, the
                        disclosure of personal data to a processor that processes personal
                        data on behalf of a controller does not constitute a sale. <br/> As
                        specified in the “Detailed information on the processing of Personal
                        Data” section of this document, our use of your personal information
                        may be considered a sale under VCDPA.
                    </p>
                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Your right to opt out of the sale of your personal data and how you
                        can exercise it
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to opt out of the sale of your personal data.
                        This means that whenever you request us to stop selling your data,
                        we will abide by your request. To fully exercise your right to opt
                        out you can contact us at any time using the contact details
                        provided in this document.
                    </p>
                    <p className="single-content-para pb-3">
                        We use any personal data collected from you in connection with the
                        submission of your opt-out request solely for the purpose of
                        complying with the request.
                    </p>

                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Processing of your personal data for targeted advertising
                    </h2>
                    <p className="single-content-para pb-3">
                        For our purposes, the word "targeted advertising" means "displaying
                        advertisements to you where the advertisement is selected based on
                        personal data obtained from your activities over time and across
                        nonaffiliated websites or online applications to predict your
                        preferences or interests" as defined by the VCDPA
                    </p>
                    <p className="single-content-para pb-3">
                        To find out more details on the processing of your personal data for
                        targeted advertising purposes, you can read the section titled
                        “Detailed information on the processing of Personal Data” within
                        this document.
                    </p>

                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        Your right to opt out of the processing of your personal data for
                        targeted advertising and how you can exercise it
                    </h2>
                    <p className="single-content-para pb-3">
                        You have the right to opt out of the processing of your personal
                        data for targeted advertising. This means that whenever you ask us
                        to stop processing your data for targeted advertising, we will abide
                        by your request. To fully exercise your right to opt out you can
                        contact us at any time, using the contact details provided in this
                        document.
                    </p>
                    <p className="single-content-para pb-3">
                        We use any personal data collected from you in connection with the
                        submission of your opt-out request solely for the purposes of
                        complying with the opt-out request.
                    </p>

                    <h2 className="single-content-sub-heading pt-0">
                        Your privacy rights under the Virginia Consumer Data Protection Act
                        and how to exercise them
                    </h2>
                    <p className="single-content-para pb-3">
                        You may exercise certain rights regarding your data processed by us.
                        In particular, you have the right to do the following:
                    </p>

                    <ul>
                        <li>
                            <b>access personal data: the right to know.</b> You have the right
                            to request that we confirm whether or not we are processing your
                            personal data. You also have the right to access such personal
                            data.
                        </li>
                        <li>
                            <b>correct inaccurate personal data.</b> You have the right to
                            request that we correct any inaccurate personal data we maintain
                            about you, taking into account the nature of the personal data and
                            the purposes of the processing of the personal data.
                        </li>
                        <li>
                            <b>request the deletion of your personal data.</b> You have the
                            right to request that we delete any of your personal data.
                        </li>
                        <li>
                            <b>obtain a copy of your personal data.</b> We will provide your
                            personal data in a portable and usable format that allows you to
                            transfer data easily to another entity – provided that this is
                            technically feasible.
                        </li>
                        <li>
                            <b>
                                opt out of the processing of your personal data for the purposes
                                of targeted advertising
                            </b>
                            , the <b>sale of personal data</b>, or <b>profiling</b> in
                            furtherance of decisions that produce legal or similarly
                            significant effects concerning you.
                        </li>
                        <li>
                            <b>non-discrimination.</b> We will not discriminate against you
                            for exercising your rights under the VCDPA. This means that we
                            will not, among other things, deny goods or services, charge you a
                            different price, or provide a different level or quality of goods
                            or services just because you exercised your consumer privacy
                            rights. However, if you refuse to provide your personal data to us
                            or ask us to delete or stop selling your personal data, and that
                            personal data or sale is necessary for us to provide you with
                            goods or services, we may not be able to complete that
                            transaction. To the extent permitted by the law, we may offer a
                            different price, rate, level, quality, or selection of goods or
                            services to you, including offering goods or services for no fee,
                            if you have exercised your right to opt out, or our offer is
                            related to your voluntary participation in a bona fide loyalty,
                            rewards, premium features, discounts, or club card program.
                        </li>
                    </ul>

                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        How to exercise your rights
                    </h2>
                    <p className="single-content-para pb-3">
                        To exercise the rights described above, you need to submit your
                        request to us by contacting us via the contact details provided in
                        this document.
                    </p>
                    <p className="single-content-para pb-3">
                        For us to respond to your request, we need to know who you are.
                    </p>
                    <p className="single-content-para pb-3">
                        We will not respond to any request if we are unable to verify your
                        identity using commercially reasonable efforts and therefore confirm
                        that the personal data in our possession actually relates to you. In
                        such cases, we may request that you provide additional information
                        which is reasonably necessary to authenticate you and your request.
                    </p>
                    <p className="single-content-para pb-3">
                        Making a consumer request does not require you to create an account
                        with us. However, we may require you to use your existing account.
                        We will use any personal data collected from you in connection with
                        your request solely for the purposes of authentication, without
                        further disclosing the personal data, retaining it longer than
                        necessary for purposes of authentication, or using it for unrelated
                        purposes.
                    </p>
                    <p className="single-content-para pb-3">
                        If you are an adult, you can make a request on behalf of a minor
                        under your parental authority.
                    </p>

                    <h2 className="single-content-sub-heading pb-3 pt-0">
                        How and when we are expected to handle your request
                    </h2>
                    <p className="single-content-para pb-3">
                        We will respond to your request without undue delay, but in all
                        cases and at the latest within 45 days of its receipt. Should we
                        need more time, we will explain to you the reasons why, and how much
                        more time we need. In this regard, please note that we may take up
                        to 90 days to fulfill your request.
                    </p>
                    <p className="single-content-para pb-3">
                        Should we deny your request, we will explain to you the reasons
                        behind our denial without undue delay, but in all cases and at the
                        latest within 45 days of receipt of the request. It is your right to
                        appeal such decision by submitting a request to us via the details
                        provided in this document. Within 60 days of receipt of the appeal,
                        we will inform you in writing of any action taken or not taken in
                        response to the appeal, including a written explanation of the
                        reasons for the decisions. If the appeal is denied you may contact
                        the Attorney General to submit a complaint.
                    </p>
                    <p className="single-content-para">
                        We do not charge a fee to respond to your request, for up to two
                        requests per year. If your request is manifestly unfounded,
                        excessive or repetitive, we may charge a reasonable fee or refuse to
                        act on the request. In either case, we will communicate our choices
                        and explain the reasons behind them.
                    </p>
                </div>
                <div className="content-wrapper">
                    <Row>
                        <Col lg={12}>
                            <Accordion>
                                <Card>
                                    <Card.Header>
                                        <AccordionButtonToggle eventKey="0">
                                            <div className="d-flex button-container tag-btn-container">
                                                <img
                                                    src="/images/tag.png"
                                                    alt=""
                                                    className="tag-image"
                                                    loading="lazy"
                                                />
                                                <h2 className="single-text-accordion">
                                                    Definitions and legal references
                                                </h2>
                                            </div>
                                        </AccordionButtonToggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="accordion-body">
                                        <Card.Body>
                                            <h2 className="single-content-sub-heading pt-0">
                                                Personal Data (or Data)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Any information that directly, indirectly, or in
                                                connection with other information — including a personal
                                                identification number — allows for the identification or
                                                identifiability of a natural person.
                                            </p>
                                            <h2 className="single-content-sub-heading pt-0">
                                                Usage Data
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Information collected automatically through this Website
                                                (or third-party services employed in this Website),
                                                which can include: the IP addresses or domain names of
                                                the computers utilized by the Users who use this
                                                Website, the URI addresses (Uniform Resource
                                                Identifier), the time of the request, the method
                                                utilized to submit the request to the server, the size
                                                of the file received in response, the numerical code
                                                indicating the status of the server's answer (successful
                                                outcome, error, etc.), the country of origin, the
                                                features of the browser and the operating system
                                                utilized by the User, the various time details per visit
                                                (e.g., the time spent on each page within the
                                                Application) and the details about the path followed
                                                within the Application with special reference to the
                                                sequence of pages visited, and other parameters about
                                                the device operating system and/or the User's IT
                                                environment.
                                            </p>
                                            <h2 className="single-content-sub-heading pt-0">User</h2>
                                            <p className="single-content-para pb-3">
                                                The individual using this Website who, unless otherwise
                                                specified, coincides with the Data Subject.
                                            </p>
                                            <h2 className="single-content-sub-heading pt-0">
                                                Data Subject
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                The natural person to whom the Personal Data refers.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                Data Processor (or Data Supervisor)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                The natural or legal person, public authority, agency or
                                                other body which processes Personal Data on behalf of
                                                the Controller, as described in this privacy policy.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                Data Controller (or Owner)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                The natural or legal person, public authority, agency or
                                                other body which, alone or jointly with others,
                                                determines the purposes and means of the processing of
                                                Personal Data, including the security measures
                                                concerning the operation and use of this Website. The
                                                Data Controller, unless otherwise specified, is the
                                                Owner of this Website.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                This Website (or this Application)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                The means by which the Personal Data of the User is
                                                collected and processed.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                Service
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                The service provided by this Website as described in the
                                                relative terms (if available) and on this
                                                site/application.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                European Union (or EU)
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Unless otherwise specified, all references made within
                                                this document to the European Union include all current
                                                member states to the European Union and the European
                                                Economic Area.
                                            </p>

                                            <h2 className="single-content-sub-heading pt-0">
                                                Cookie
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                Cookies are Trackers consisting of small sets of data
                                                stored in the User's browser.
                                            </p>
                                            <h2 className="single-content-sub-heading pt-0">
                                                Tracker
                                            </h2>
                                            <p className="single-content-para pb-3 dotted-border">
                                                Tracker indicates any technology - e.g Cookies, unique
                                                identifiers, web beacons, embedded scripts, e-tags and
                                                fingerprinting - that enables the tracking of Users, for
                                                example by accessing or storing information on the
                                                User’s device.
                                            </p>
                                            <h2 className="single-content-sub-heading pt-4">
                                                Legal information
                                            </h2>
                                            <p className="single-content-para pb-3">
                                                This privacy statement has been prepared based on
                                                provisions of multiple legislations, including Art.
                                                13/14 of Regulation (EU) 2016/679 (General Data
                                                Protection Regulation).
                                            </p>
                                            <p className="single-content-para pb-1">
                                                This privacy policy relates solely to this Website, if
                                                not stated otherwise within this document.
                                            </p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                </div>
                <div className="footer-wrapper">
                    <p className="para-frist">Latest update: December 29, 2022</p>
                    <p className="para-second">
                        <span className="single-link">iubenda</span> hosts this content and
                        only
                        <span className="single-link">
              collects the Personal Data strictly necessary
            </span>
                        for it to be provided.
                    </p>
                    <div className="d-flex justify-content-end">
                        <p className="para-frist single-link" onClick={togglePrivacyPolicy}>
                            Show the complete Privacy Policy
                        </p>
                    </div>
                </div>
            </div>
            {acceptbtn && (
                <div className="d-flex justify-content-end w-100 pt-4 pr-3">
                    <Button
                        type="button"
                        text="Accept"
                        className="single-btn"
                        onClick={acceptCloseBtn}
                    />
                </div>
            )}
        </Fragment>
    );
});
export default CompletePrivacyPolicy;

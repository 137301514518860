import React from "react";
import ModalCommon from "../../ui/modal";
import "./TermConditionModal.View.scss";
import TermCondition from "../term-condition-body/TermCondition.View";

const TermConditionModal = (props) => {
    const {open, toggle} = props;

    return (
        <ModalCommon
            show={open}
            onHide={toggle}
            closemodal={toggle}
            contentClassName="privacy-policy-container"
            titleclass="modal-title-class"
            titleheader={<></>}
            headerclass="modal-header-class"
            footerclass="modal-footer-class"
            body={<TermCondition/>}
        />
    );
};
export default TermConditionModal;

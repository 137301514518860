import {useElements, useStripe, PaymentElement} from '@stripe/react-stripe-js';
import React, {useEffect, useState} from 'react';
import {useOrder} from '../../../contexts/OrderContext';
import {usePage} from '../../../contexts/PageContext';
import {formatPriceStatic, getCookie, retailAPI} from '../../../utils/common';
import Button from '../../ui/button/Button';
import CheckoutPayment from '../checkout-payment';
import './CheckoutTab.View.scss';

const CheckoutTab = () => {
    const {totalCartItemsPrice, setCartItems, priceDetails} = useOrder();
    const {setIsMenubarOpen, setClientSecret} = usePage();
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('');
    const [addressLine1, setAddressLine1] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [name, setName] = useState('');
    const [isPaymentElementComplete, setIsPaymentElementComplete] = useState(false);

    useEffect(() => {
        if (!elements) {
            return;
        }

        const paymentElement = elements.getElement(PaymentElement);

        if (paymentElement) {
            paymentElement.on('change', (event) => {
                setIsPaymentElementComplete(event.complete);
            });
        }
    }, [elements]);

    const pushPurchaceData = () => {
        // push details to add-to-cart
        let post_data = {
            eventType: 'purchase-complete',
            eventTime: new Date().toISOString(),
            userInfo: {
                userAgent: navigator.userAgent,
                ipAddress: '192.168.1.100',
            },
            visitorId: getCookie('_ga'),
            productDetails: [],
            attributes: {
                global_website: {
                    text: [window.location.host],
                    indexable: true,
                    searchable: true,
                },
            },
        };

        return retailAPI(post_data);
    };

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        // console.log(
        // 	`name: ${name}, email: ${email}, phoneNumber: ${phoneNumber}, country: ${country}, addressLine1: ${addressLine1}, city: ${city}, postalCode: ${postalCode}, isPaymentElementComplete: ${isPaymentElementComplete}`
        // );

        if (
            !name ||
            !email ||
            !phoneNumber ||
            !country ||
            !addressLine1 ||
            !city ||
            !postalCode ||
            !isPaymentElementComplete
        ) {
            // return alert(
            // 	`Please fill the following fields in the form: ${!name ? '\n• Full Name' : ''} ${
            // 		!email ? '\n• Email Address' : ''
            // 	} ${!phoneNumber ? '\n• Phone Number' : ''} ${!country ? '\n• Country' : ''} ${
            // 		!addressLine1 ? '\n• Address Line 1' : ''
            // 	} ${!city ? '\n• City' : ''} ${!postalCode ? '\n• Postal Code' : ''} ${
            // 		!isPaymentElementComplete ? '\n• Card Info' : ''
            // 	}`
            // );
            setProcessing(false);
        } else setProcessing(true);

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: 'http://localhost:3000',
                payment_method_data: {
                    billing_details: {
                        name,
                        email,
                        phone: phoneNumber,
                    },
                },
            },
            redirect: 'if_required',
        });
        setProcessing(false);
        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            console.log(error);
            alert(error.message);
        } else {
            setIsMenubarOpen(false);
            setCartItems([]);
            setClientSecret('');
            pushPurchaceData()
            alert(
                'Your order has been successfully received. An email receipt will be received shortly.'
            );
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <CheckoutPayment
                    setEmail={setEmail}
                    setName={setName}
                    setPhoneNumber={setPhoneNumber}
                    setCountry={setCountry}
                    setAddressLine1={setAddressLine1}
                    setCity={setCity}
                    setPostalCode={setPostalCode}
                />
                {/* <label className='d-block'>
          <label className='container-checkbox'>
            Billing and shipping address are the same
            <input
              type='checkbox'
              onChange={() => setIsAddressSame(!isAddressSame)}
              // {...methods.register(`${name}.email`)}
            />
            <span className='checkmark'></span>
          </label>
        </label> */}
            </div>

            <Button
                disabled={!stripe || processing}
                type='submit'
                text={
                    processing ? (
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '10px',
                            }}
                        >
							{'Processing your order'}
                            <span className='loader-circle'></span>
						</span>
                    ) : (
                        `Pay ${formatPriceStatic(totalCartItemsPrice, priceDetails?.currency_code)}`
                    )
                }
                className='save-btn'
            />
        </form>
    );
};
export default CheckoutTab;

import React from 'react';
import OrderProvider from './OrderContext';
import PageProvider from './PageContext';
const Providers = ({ children }) => {
  return (
    <PageProvider>
      <OrderProvider>{children}</OrderProvider>
    </PageProvider>
  );
};

export default Providers;

import React, {Fragment, memo} from "react";
import "./PartialCookiePolicy.View.scss";
import {Accordion, Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {AccordionButtonToggle} from "../complete-privacy-policy/CompletePrivacyPolicy.View";
import Button from "../../ui/button/Button";

const PartialCookiePolicy = memo(({
                                      togglePrivacyPolicy,
                                      acceptbtn,
                                      acceptCloseBtn,
                                      customHeightLocationCookie,
                                  }) => {
    return (
        <Fragment>
            <div
                className={`body-wrapper-cookie ${
                    customHeightLocationCookie ? "custom-height-location-cookie" : "body-wrapper-cookie-height"
                }`}
            >
                <div className="cookie-header-container">
                    <h4 className="heading-cookie">Cookie Policy of floatpic.com</h4>
                    <p className="head-para-cookie pb-3">
                        This document informs Users about the technologies that help this
                        Website to achieve the purposes described below. Such technologies
                        allow the Owner to access and store information (for example by
                        using a Cookie) or use resources (for example by running a script)
                        on a User’s device as they interact with this Website.
                    </p>
                    <p className="head-para-cookie pb-3">
                        For simplicity, all such technologies are defined as "Trackers"
                        within this document – unless there is a reason to differentiate.{" "}
                        <br/> For example, while Cookies can be used on both web and mobile
                        browsers, it would be inaccurate to talk about Cookies in the
                        context of mobile apps as they are a browser-based Tracker. For this
                        reason, within this document, the term Cookies is only used where it
                        is specifically meant to indicate that particular type of Tracker.
                    </p>
                    <p className="head-para-cookie pb-3">
                        Some of the purposes for which Trackers are used may also require
                        the User's consent. Whenever consent is given, it can be freely
                        withdrawn at any time following the instructions provided in this
                        document.
                    </p>
                    <p className="head-para-cookie pb-3">
                        This Website uses Trackers managed directly by the Owner (so-called
                        “first-party” Trackers) and Trackers that enable services provided
                        by a third-party (so-called “third-party” Trackers). Unless
                        otherwise specified within this document, third-party providers may
                        access the Trackers managed by them. <br/> The validity and
                        expiration periods of Cookies and other similar Trackers may vary
                        depending on the lifetime set by the Owner or the relevant provider.
                        Some of them expire upon termination of the User’s browsing session.
                        In addition to what’s specified in the descriptions within each of
                        the categories below, Users may find more precise and updated
                        information regarding lifetime specification as well as any other
                        relevant information – such as the presence of other Trackers - in
                        the linked privacy policies of the respective third-party providers
                        or by contacting the Owner.
                    </p>
                    <p className="head-para-cookie pb-3">
                        To find more information dedicated to Californian consumers and
                        their privacy rights, Users may read the privacy policy.
                    </p>
                </div>
                <h2 className="body-heading pb-3">
                    Activities strictly necessary for the operation of this Website and
                    delivery of the Service
                </h2>
                <p className="head-para-cookie pb-3">
                    This Website uses so-called “technical” Cookies and other similar
                    Trackers to carry out activities that are strictly necessary for the
                    operation or delivery of the Service.
                </p>
                <h2 className="single-content-sub-heading pt-0 pb-3">
                    Third-party Trackers
                </h2>
                <Row>
                    <Col lg={12}>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <AccordionButtonToggle eventKey="0">
                                        <div className="d-flex button-container">
                                            <h2 className="single-text-accordion">Tag Management</h2>
                                        </div>
                                    </AccordionButtonToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="accordion-body">
                                    <Card.Body>
                                        <p className="single-content-para pb-3">
                                            This type of service helps the Owner to manage the tags or
                                            scripts needed on this Website in a centralized fashion.
                                            This results in the Users' Data flowing through these
                                            services, potentially resulting in the retention of this
                                            Data.
                                        </p>
                                        <p className="single-content-sub-heading pb-1">
                                            Google Tag Manager (Google LLC)
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Google Tag Manager is a tag management service provided by
                                            Google LLC.
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Personal Data processed: Tracker and Usage Data.
                                        </p>
                                        <p className="single-content-para">
                                            Place of processing: United States – Privacy Policy.
                                        </p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <h2 className="body-heading pb-4">
                    Other activities involving the use of Trackers
                </h2>
                <p className="single-content-sub-heading pb-3">
                    Experience enhancement
                </p>
                <p className="head-para-cookie pb-3">
                    This Website uses Trackers to provide a personalized user experience
                    by improving the quality of preference management options, and by
                    enabling interaction with external networks and platforms.
                </p>
                <Row>
                    <Col lg={12}>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <AccordionButtonToggle eventKey="0">
                                        <div className="d-flex button-container">
                                            <h2 className="single-text-accordion">
                                                Displaying content from external platforms
                                            </h2>
                                        </div>
                                    </AccordionButtonToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="accordion-body">
                                    <Card.Body>
                                        <p className="single-content-para pb-3">
                                            This type of service allows you to view content hosted on
                                            external platforms directly from the pages of this Website
                                            and interact with them. <br/> This type of service might
                                            still collect web traffic data for the pages where the
                                            service is installed, even when Users do not use it.
                                        </p>
                                        <p className="single-content-sub-heading pb-1">
                                            Font Awesome (Fonticons, Inc. )
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Font Awesome is a typeface visualization service provided
                                            by Fonticons, Inc. that allows this Website to incorporate
                                            content of this kind on its pages.
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Personal Data processed: Tracker and Usage Data.
                                        </p>
                                        <p className="single-content-para">
                                            Place of processing: United States – Privacy Policy.
                                        </p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <p className="single-content-sub-heading pb-3 mt-3">Measurement</p>
                <p className="head-para-cookie pb-3">
                    This Website uses Trackers to measure traffic and analyze User
                    behavior with the goal of improving the Service.
                </p>
                <Row>
                    <Col lg={12}>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <AccordionButtonToggle eventKey="0">
                                        <div className="d-flex button-container">
                                            <h2 className="single-text-accordion">Analytics</h2>
                                        </div>
                                    </AccordionButtonToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="accordion-body">
                                    <Card.Body>
                                        <p className="single-content-para pb-3">
                                            The services contained in this section enable the Owner to
                                            monitor and analyze web traffic and can be used to keep
                                            track of User behavior.
                                        </p>
                                        <p className="single-content-sub-heading pb-1">
                                            Google Analytics (Google LLC)
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Google Analytics is a web analysis service provided by
                                            Google LLC (“Google”). Google utilizes the Data collected
                                            to track and examine the use of this Website, to prepare
                                            reports on its activities and share them with other Google
                                            services. Google may use the Data collected to
                                            contextualize and personalize the ads of its own
                                            advertising network.
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Personal Data processed: Tracker and Usage Data.
                                        </p>
                                        <p className="single-content-para pb-2">
                                            Place of processing: United States – Privacy Policy.
                                        </p>
                                        <p className="single-content-para">Storage duration:</p>
                                        <ul>
                                            {" "}
                                            <li>AMP_TOKEN: 1 hour</li>
                                            <li>_ga: 2 years</li>
                                            {" "}
                                            <li>_gac*: 3 months</li>
                                            <li>_gat: 1 minute</li>
                                            {" "}
                                            <li>_gid: 1 day</li>
                                            {" "}
                                        </ul>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <h2 className="body-heading pb-3">
                    Activities that do not fit the above categorizations
                </h2>
                <Row>
                    <Col lg={12}>
                        <Accordion>
                            <Card>
                                <Card.Header>
                                    <AccordionButtonToggle eventKey="0">
                                        <div className="d-flex button-container">
                                            <img src="/images/content.png" alt=""
                                                 loading="lazy"/>
                                            <h2 className="single-text-accordion">
                                                This policy is applicable to FloatPic Inc. worldwide
                                                sites:
                                            </h2>
                                        </div>
                                    </AccordionButtonToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0" className="accordion-body">
                                    <Card.Body>
                                        <p className="single-content-para">floatpic.com</p>
                                        <p className="single-content-para">ca.floatpic.com</p>
                                        <p className="single-content-para">uk.floatpic.com</p>
                                        <p className="single-content-para">au.floatpic.com</p>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </Col>
                </Row>
                <h2 className="body-heading pb-3">
                    How to manage preferences and provide or withdraw consent
                </h2>
                <p className="head-para-cookie pb-3">
                    There are various ways to manage Tracker related preferences and to
                    provide and withdraw consent, where relevant:
                </p>
                <p className="head-para-cookie pb-3">
                    Users can manage preferences related to Trackers from directly within
                    their own device settings, for example, by preventing the use or
                    storage of Trackers.
                </p>
                <p className="head-para-cookie pb-3">
                    Additionally, whenever the use of Trackers is based on consent, Users
                    can provide or withdraw such consent by setting their preferences
                    within the cookie notice or by updating such preferences accordingly
                    via the relevant consent-preferences widget, if available.
                </p>
                <p className="head-para-cookie pb-3">
                    It is also possible, via relevant browser or device features, to
                    delete previously stored Trackers, including those used to remember
                    the User’s initial consent.
                </p>
                <p className="head-para-cookie pb-3">
                    Other Trackers in the browser’s local memory may be cleared by
                    deleting the browsing history.
                </p>
                <p className="head-para-cookie pb-3">
                    With regard to any third-party Trackers, Users can manage their
                    preferences and withdraw their consent via the related opt-out link
                    (where provided), by using the means indicated in the third party's
                    privacy policy, or by contacting the third party.
                </p>
                <h2 className="single-content-sub-heading pt-0 pb-3">
                    Locating Tracker Settings
                </h2>
                <p className="head-para-cookie pb-3">
                    Users can, for example, find information about how to manage Cookies
                    in the most commonly used browsers at the following addresses:
                </p>
                <ul>
                    <li>
                        <Link
                            to="https://support.google.com/chrome/answer/95647?hl=en&amp;p=cpn_cookies"
                            className="single-link"
                        >
                            Google Chrome
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                            className="single-link"
                        >
                            Mozilla Firefox
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
                            className="single-link"
                        >
                            Apple Safari
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
                            className="single-link"
                        >
                            Microsoft Internet Explorer
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://support.microsoft.com/en-us/help/4027947"
                            className="single-link"
                        >
                            Microsoft Edge
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
                            className="single-link"
                        >
                            Microsoft Edge
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="https://help.opera.com/en/latest/web-preferences/#cookies"
                            className="single-link"
                        >
                            Opera
                        </Link>
                    </li>
                </ul>
                <p className="head-para-cookie pb-3">
                    Users may also manage certain categories of Trackers used on mobile
                    apps by opting out through relevant device settings such as the device
                    advertising settings for mobile devices, or tracking settings in
                    general (Users may open the device settings and look for the relevant
                    setting).
                </p>
                <h2 className="single-content-sub-heading pt-0 pb-3">
                    Consequences of denying consent
                </h2>
                <p className="head-para-cookie pb-3">
                    Users are free to decide whether or not to grant consent. However,
                    please note that Trackers help this Website to provide a better
                    experience and advanced functionalities to Users (in line with the
                    purposes outlined in this document). Therefore, in the absence of the
                    User's consent, the Owner may be unable to provide related features.
                </p>
                <div className="">
                    <h2 className="single-content-heading">Owner and Data Controller</h2>
                    <p className="single-content-para">FloatPic Inc</p>
                    <p className="single-content-para">4025 Dorchester Rd.</p>
                    <p className="single-content-para">Suite 336</p>
                    <p className="single-content-para">Niagara Falls, Ontario L2E 7K8</p>
                    <p className="single-content-para mb-3">Canada</p>
                    <p className="single-content-para">
                        These policies are applicable to FloatPic Inc.
                    </p>
                    <p className="single-content-para mb-3">worldwide sites:</p>
                    <p className="single-content-para">floatpic.com</p>
                    <p className="single-content-para">ca.floatpic.com</p>
                    <p className="single-content-para">uk.floatpic.com</p>
                    <p className="single-content-para mb-3">au.floatpic.com</p>
                    <p className="single-content-para">
                        <strong className="email-heading">Owner contact email:</strong>{" "}
                        admin@floatpic.com
                    </p>
                    <p className="head-para-cookie pb-3">
                        Since the use of third-party Trackers through this Website cannot be
                        fully controlled by the Owner, any specific references to
                        third-party Trackers are to be considered indicative. In order to
                        obtain complete information, Users are kindly requested to consult
                        the privacy policies of the respective third-party services listed
                        in this document.
                    </p>
                    <p className="head-para-cookie pb-3">
                        Given the objective complexity surrounding tracking technologies,
                        Users are encouraged to contact the Owner should they wish to
                        receive any further information on the use of such technologies by
                        this Website.
                    </p>
                    <div className="content-wrapper">
                        <Row>
                            <Col lg={12}>
                                <Accordion>
                                    <Card>
                                        <Card.Header>
                                            <AccordionButtonToggle eventKey="0">
                                                <div className="d-flex button-container">
                                                    <h2 className="single-text-accordion">
                                                        Definitions and legal references
                                                    </h2>
                                                </div>
                                            </AccordionButtonToggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0" className="accordion-body">
                                            <Card.Body>
                                                <h2 className="single-content-sub-heading pt-0">
                                                    Personal Data (or Data)
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    Any information that directly, indirectly, or in
                                                    connection with other information — including a
                                                    personal identification number — allows for the
                                                    identification or identifiability of a natural person.
                                                </p>
                                                <h2 className="single-content-sub-heading pt-0">
                                                    Usage Data
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    Information collected automatically through this
                                                    Website (or third-party services employed in this
                                                    Website), which can include: the IP addresses or
                                                    domain names of the computers utilized by the Users
                                                    who use this Website, the URI addresses (Uniform
                                                    Resource Identifier), the time of the request, the
                                                    method utilized to submit the request to the server,
                                                    the size of the file received in response, the
                                                    numerical code indicating the status of the server's
                                                    answer (successful outcome, error, etc.), the country
                                                    of origin, the features of the browser and the
                                                    operating system utilized by the User, the various
                                                    time details per visit (e.g., the time spent on each
                                                    page within the Application) and the details about the
                                                    path followed within the Application with special
                                                    reference to the sequence of pages visited, and other
                                                    parameters about the device operating system and/or
                                                    the User's IT environment.
                                                </p>
                                                <h2 className="single-content-sub-heading pt-0">
                                                    User
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The individual using this Website who, unless
                                                    otherwise specified, coincides with the Data Subject.
                                                </p>
                                                <h2 className="single-content-sub-heading pt-0">
                                                    Data Subject
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The natural person to whom the Personal Data refers.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    Data Processor (or Data Supervisor)
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The natural or legal person, public authority, agency
                                                    or other body which processes Personal Data on behalf
                                                    of the Controller, as described in this privacy
                                                    policy.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    Data Controller (or Owner)
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The natural or legal person, public authority, agency
                                                    or other body which, alone or jointly with others,
                                                    determines the purposes and means of the processing of
                                                    Personal Data, including the security measures
                                                    concerning the operation and use of this Website. The
                                                    Data Controller, unless otherwise specified, is the
                                                    Owner of this Website.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    This Website (or this Application)
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The means by which the Personal Data of the User is
                                                    collected and processed.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    Service
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    The service provided by this Website as described in
                                                    the relative terms (if available) and on this
                                                    site/application.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    European Union (or EU)
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    Unless otherwise specified, all references made within
                                                    this document to the European Union include all
                                                    current member states to the European Union and the
                                                    European Economic Area.
                                                </p>

                                                <h2 className="single-content-sub-heading pt-0">
                                                    Cookie
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    Cookies are Trackers consisting of small sets of data
                                                    stored in the User's browser.
                                                </p>
                                                <h2 className="single-content-sub-heading pt-0">
                                                    Tracker
                                                </h2>
                                                <p className="single-content-para pb-3 dotted-border">
                                                    Tracker indicates any technology - e.g Cookies, unique
                                                    identifiers, web beacons, embedded scripts, e-tags and
                                                    fingerprinting - that enables the tracking of Users,
                                                    for example by accessing or storing information on the
                                                    User’s device.
                                                </p>
                                                <h2 className="single-content-sub-heading pt-4">
                                                    Legal information
                                                </h2>
                                                <p className="single-content-para pb-3">
                                                    This privacy statement has been prepared based on
                                                    provisions of multiple legislations, including Art.
                                                    13/14 of Regulation (EU) 2016/679 (General Data
                                                    Protection Regulation).
                                                </p>
                                                <p className="single-content-para pb-1">
                                                    This privacy policy relates solely to this Website, if
                                                    not stated otherwise within this document.
                                                </p>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="footer-wrapper">
                    <p className="para-frist">Latest update: December 29, 2022</p>
                    <p className="para-second">
                        <Link
                            to="https://www.iubenda.com/en/privacy-and-cookie-policy-generator"
                            className="single-link"
                        >
                            iubenda
                        </Link>{" "}
                        hosts this content and only{" "}
                        <Link
                            to="https://www.iubenda.com/privacy-policy/65675001"
                            className="single-link"
                        >
                            collects the Personal Data strictly necessary
                        </Link>{" "}
                        for it to be provided.
                    </p>
                    <div className="d-flex justify-content-end">
                        <p className="para-frist single-link" onClick={togglePrivacyPolicy}>
                            Show the complete Privacy Policy
                        </p>
                    </div>
                </div>
            </div>
            {acceptbtn && (
                <div className="d-flex justify-content-end w-100 pt-4 pr-3">
                    <Button
                        type="button"
                        text="Accept"
                        className="single-btn"
                        onClick={acceptCloseBtn}
                    />
                </div>
            )}
        </Fragment>
    );
});
export default PartialCookiePolicy;

import React, {Fragment, useEffect, useState} from 'react';
import {History, Keyboard, Manipulation, Mousewheel, Navigation} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import UseWindowDimensions from '../../../../components/snippets/use-window-dimensions';

import {GrFormNext} from 'react-icons/gr';
import {useOrder} from '../../../../contexts/OrderContext';
import {usePage} from '../../../../contexts/PageContext';

import CookieCondition from '../../../../components/features/cookie-condition/CookieCondition.View';
import BorderSwitcher from '../../../../components/snippets/border-switcher';
import {getCookie, retailAPI, stopWatch,} from '../../../../utils/common';
import TouchDeviceOption from "./TouchDeviceOption";
import NoTouchOption from "./NoTouchOption";
import CartWrapper from "../../../snippets/cart-wrapper";
import {useNavigate} from "react-router-dom";

const RecommendationsSlides = ({loadNewProduct, setSwiper, onSlide}) => {
    const [apiInterval, setApiInterval] = useState(null);
    const [cartItemAdded, setCartItemAdded] = useState(null);
    const [showBorderSwitchId, setShowBorderSwitchId] = useState(window.innerWidth < 600);
    const [currentSlideId, setCurrentSlideId] = useState(null);
    const {height} = UseWindowDimensions();
    const navigate = useNavigate();

    const {
        addToCart,
        priceDetails,
    } = useOrder();
    const {
        showing,
        setIsMenubarOpen,
        floatPictures,
        setFloatPictures,
        setBorderPreference,
        swiperProduct,
        setSwiperProduct,
        setTransitionStarted,
        filteredFloatPictures,
        setFilteredFloatPictures,
        currentSlider,
        idFromUrl,
        setArtTimes,
        artTimes,
        setCurrentSlide,
        previousArtId,
        setPreviousArtId,
        showOnboarding,
        setShowOnboarding,
        isAddToCartOpen,
        setIsAddToCartOpen,
        setZoomedProductId,
        setZoomedProduct,
        setZoomed,
        setActiveMenuItem,
        isTouchDevice,
        setActiveMenu
    } = usePage();

    const detailPageViewAiApi = (current_id) => {
        // push details to ai-recommendations
        let post_data = {
            eventType: 'detail-page-view',
            eventTime: new Date().toISOString(),
            userInfo: {
                userAgent: navigator.userAgent,
                ipAddress: '192.168.1.100',
            },
            visitorId: getCookie('_ga'),
            productDetails: [
                {
                    product: {
                        id: current_id,
                    },
                },
            ],
            attributes: {
                global_website: {
                    text: [window.location.host],
                    indexable: true,
                    searchable: true,
                },
            },
        };

        return retailAPI(post_data);
        // push details to ai-recommendations
    };

    useEffect(() => {
        if (apiInterval) {
            clearInterval(apiInterval);
        }

        const newInterval = setInterval(() => {
            detailPageViewAiApi(currentSlideId);
        }, 4000);

        setApiInterval(newInterval);

        return () => clearInterval(newInterval);
    }, [currentSlideId]);

    const handleSwiperImageClick = (clickedItem) => {
        const clicked = floatPictures.find((item) => item.art_id === clickedItem.art_id);
        const tb = clicked.img.includes('-TB');
        const bw = clicked.img.includes('-BW');
        if (tb) {
            setBorderPreference('-BW');
        }
        if (bw) {
            setBorderPreference('-TB');
        }

        const pictures = floatPictures.map((item) => {
            item.img = tb ? item.imgBW : item.imgTB;
            return item;
        });
        setFloatPictures(pictures);
    };

    const handleFilterSwiperImageClick = (clickedItem) => {
        const clicked = filteredFloatPictures.find((item) => item.art_id === clickedItem.art_id);
        const tb = clicked.img.includes('-TB');
        const bw = clicked.img.includes('-BW');
        if (tb) {
            setBorderPreference('-BW');
        }
        if (bw) {
            setBorderPreference('-TB');
        }

        const pictures = filteredFloatPictures.map((item) => {
            item.img = tb ? item.imgBW : item.imgTB;
            return item;
        });
        setFilteredFloatPictures(pictures);
    };

    const handleOnboardingClick = () => {
        setShowOnboarding(false);
        swiperProduct?.slideNext();
    };

    const onBeforeInit = async (swiper) => {
        if (!swiper) return;
        if (currentSlider === 'products') {
            setSwiperProduct(swiper);
            window.fu = swiper;
        } else {
            setSwiper(swiper);
        }
        if (idFromUrl && swiper?.slides) {
            const slide = swiper.slides.find(item => item.getAttribute('data-history') === idFromUrl);
            if (slide) {
                const index = swiper.slides.indexOf(slide);
                swiper.slideTo(index, 0);
            }
        }

        swiper.update();
    }

    const onSlideChange = async (swiper) => {
        setIsAddToCartOpen(false);
        setShowBorderSwitchId(null);
        if (!swiper) return;
        if (currentSlider === 'products') {
            setCartItemAdded(null);
            await loadNewProduct(3);
            if (showOnboarding) {
                setShowOnboarding(false);
            }
            //----
            const slide = swiper.slides?.[swiper.activeIndex];
            if (slide) {
                setCurrentSlide(slide.getAttribute('data-history'));
            }

            if (!artTimes) {
                setArtTimes('');
            }
            if (idFromUrl !== 'index') {
                if (previousArtId) {
                    if (previousArtId !== idFromUrl) {
                        stopWatch('stop');
                        setPreviousArtId(idFromUrl.substring(0));
                        stopWatch('start');
                    } else if (previousArtId === idFromUrl) {
                        stopWatch('stop');
                        setPreviousArtId(idFromUrl.substring(0));
                        stopWatch('start');
                    }
                } else {
                    stopWatch('start');
                    setPreviousArtId(idFromUrl.substring(0));
                }
            }
        }
        swiper.update();
    }

    const isProductSlider = showing === 'allProducts' && currentSlider === 'products';

    const setCurrentState = async (swiper) => {
        if (!swiper || !swiper.slides) return;
        const currentData = floatPictures[swiper.activeIndex];
        onSlide(currentData.art_id, currentData.art_name, currentData.artist_name)
        setCurrentSlideId(currentData.art_id)
    }

    return (
        <Fragment>
            <Swiper
                key={`${showing}`}
                modules={[
                    Navigation,
                    History,
                    Keyboard,
                    Manipulation,
                    Mousewheel,
                ]}
                history={{
                    key: 'product'
                }}
                onSlideNextTransitionStart={() => {
                    setTransitionStarted(Date.now());
                }}
                onBeforeInit={async (swiper) => {
                    if (swiper.slides) {
                        await onBeforeInit(swiper)
                        const artId = floatPictures?.[swiper.activeIndex || 0]?.art_id;
                        navigate(`/product/${artId}`);
                    }
                }}
                onBeforeSlideChangeStart={() => {
                    setShowBorderSwitchId(null);
                    setZoomed(false);
                    setZoomedProductId(null);
                    setZoomedProduct(null);
                }}
                onSlideChange={async (swiper) => {
                    await onSlideChange(swiper);
                }}
                onInit={async (swiper) => {
                    setActiveMenu('recommendations')
                    await setCurrentState(swiper)
                    await loadNewProduct(3);
                }}
                onActiveIndexChange={setCurrentState}
                speed={400}
                autoHeight={true}
                grabCursor={true}
                simulateTouch={true}
                mousewheel={true}
                keyboard={true}
                touchStartPreventDefault={false}
            >
                {floatPictures.map((item) => (
                    <SwiperSlide
                        key={`products_${currentSlider}_${item.art_id}`}
                        data-history={item.art_id}
                        data-title={`${item.art_name} by ${item.artist_name} | FloatPic.com`}
                        className={`d-flex flex-column align-items-center justify-content-center w-100 [data-history="${item.art_id}"]`}
                    >
                        <div className={`swiper-slide-img-container `}
                             style={{height: height}}
                             data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            <div>
                                {isTouchDevice ? (
                                    <TouchDeviceOption item={item}
                                                       setShowBorderSwitchId={setShowBorderSwitchId}/>
                                ) : (
                                    <NoTouchOption item={item}
                                                   setShowBorderSwitchId={setShowBorderSwitchId}/>
                                )}
                            </div>
                        </div>

                        <p className={`item-name ${isAddToCartOpen ? 'item-name-hidden' : 'item-name-visible'}`}
                           style={{display: 'hidden'}}
                           data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            {item.art_name} by {item.artist_name}
                        </p>

                        {item.art_id === currentSlideId ? (
                            <BorderSwitcher
                                isOpen={showBorderSwitchId === item?.art_id}
                                onSwitchBorder={() => {
                                    if (isProductSlider) {
                                        return handleSwiperImageClick(item)
                                    }
                                    return handleFilterSwiperImageClick(item)
                                }}
                                onClickAddToOrder={(event) => {
                                    event.stopPropagation();
                                    setIsAddToCartOpen(true);
                                    setIsMenubarOpen(false);
                                    setCartItemAdded(item);
                                }}
                                item={item}
                            />
                        ) : <></>}
                    </SwiperSlide>
                ))}
            </Swiper>

            {isProductSlider ? (
                <Fragment>
                    {showOnboarding && (
                        <div
                            className='arrow-slide-main'
                            onClick={handleOnboardingClick}
                        >
                            <GrFormNext
                                // color="#4A4A4A"
                                className='text-xl arrow-slider'
                            />
                        </div>
                    )}

                    <div
                        className='d-flex align-items-center justify-content-center bg-transparent'>
                        <CookieCondition/>
                    </div>
                </Fragment>
            ) : <></>}

            {(currentSlideId === showBorderSwitchId) ? (
                <CartWrapper
                    isOpen={isAddToCartOpen}
                    onClick={async (e, itemSize, clearItemSize) => {
                        if (!itemSize) {
                            return alert('select a art type');
                        }
                        e.stopPropagation();
                        setIsAddToCartOpen(false);
                        let cartItem = {...cartItemAdded, itemSize};
                        const {S40_price, S55_price, S70_price} = priceDetails;
                        if (
                            itemSize === 'S40' ||
                            itemSize === 'TrueBorder 20' ||
                            itemSize === 'Black and White 20'
                        ) {
                            cartItem.price = S40_price;
                        } else if (
                            itemSize === 'S55' ||
                            itemSize === 'TrueBorder 40' ||
                            itemSize === 'Black and White 40'
                        ) {
                            cartItem.price = S55_price;
                        } else if (itemSize === 'S70') {
                            cartItem.price = S70_price;
                        }
                        setActiveMenuItem('3'); // setting order menu item open
                        setIsMenubarOpen(true);
                        addToCart(cartItem);
                        let post_data = {
                            eventType: 'add-to-cart',
                            eventTime: new Date().toISOString(),
                            userInfo: {
                                userAgent: navigator.userAgent,
                                ipAddress: '192.168.1.100',
                            },
                            visitorId: getCookie('_ga'),
                            productDetails: [
                                {
                                    product: {
                                        id: cartItem.art_id,
                                    },
                                    quantity: 1,
                                },
                            ],
                            attributes: {
                                global_website: {
                                    text: [window.location.host],
                                    indexable: true,
                                    searchable: true,
                                },
                            },
                        };
                        await retailAPI(post_data);
                        clearItemSize();
                    }}
                    onClose={() => setIsAddToCartOpen(false)}
                    btnText={'Add to Order'}
                />
            ) : <></>}
        </Fragment>
    );
};
export default RecommendationsSlides;

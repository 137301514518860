import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Providers from './contexts/Providers';
import HomeView from "./views/home";

const App = () => (
    <Providers>
        <BrowserRouter>
            <HomeView/>
        </BrowserRouter>
    </Providers>
);

export default App;

import React, {Fragment, useEffect, useState} from 'react';

import FloatPicWebLocation from '../../components/features/floatpic-web-location';
import {usePage} from '../../contexts/PageContext';

import 'swiper/css';
import './Home.View.scss';
import {appCountries, getHomepageMetaTitle} from "../../utils/config";
import HomeContents from "./HomeContent";
import {setCanonicalUrl} from "../../utils/common";

const HomeView = () => {
    const [isfloatPicWebLocation, setIsfloatPicWebLocation] = useState(true);

    const {
        country,
        setCountry,
        loading,
        reset,
        showing
    } = usePage();

    useEffect(() => {
        if (!country) {
            const hostname = window.location.hostname;
            const domainParts = hostname.split('.');
            const isSubdomain = !!appCountries[domainParts[0].toUpperCase()];
            if (isSubdomain) {
                setCountry(domainParts[0].toUpperCase());
            }
        } else if (country && window.location.href.includes(appCountries[country].subdomain)) {
            setIsfloatPicWebLocation(false)
        } else {
            setIsfloatPicWebLocation(true)
            setCountry(null)
        }
    }, [country])

    useEffect(() => {
        if (window.location.pathname === '/') {
            reset();
        }
    }, [window.location]);

    useEffect(() => {
        if (showing === 'home') {
            document.title = getHomepageMetaTitle();
            setCanonicalUrl()
        }
    }, [isfloatPicWebLocation, showing, country])

    return (
        <Fragment>
            {loading ? (
                <div className='ripple-container'>
                    <div className='loadingio-spinner-ripple-jce4u9nidk'>
                        <div className='ldio-i8dcdsg1jeb'>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ) : (
                <Fragment>
                    {!country && isfloatPicWebLocation ? (
                        <FloatPicWebLocation/>
                    ) : (
                        <HomeContents/>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};
export default HomeView;

import React, {Fragment, useEffect} from 'react';
import TopHeader from '../../components/snippets/top-header';

import {useOrder} from '../../contexts/OrderContext';
import {usePage} from '../../contexts/PageContext';

import 'swiper/css';
import './Home.View.scss';
import Slides from "../../components/features/home/slides";
import TempImages from "../../components/features/home/TempImages.View";
import {appApiUrl} from "../../utils/config";

const HomeContents = () => {
    const {
        setPriceDetails,
        setLoadingPrice,
    } = useOrder();

    const {
        showing,
        floatPictures,
        country,
        filteredFloatPictures,
        isAddToCartOpen,
    } = usePage();

    const loadPriceDetails = async () => {
        setLoadingPrice(true);
        try {
            const response = await fetch(
                `${appApiUrl}/product_pricing/?country=${country || 'us'}`
            );
            const {price_list, currency_code, currency_sign} = await response.json();
            const [S40_price, S55_price, S70_price] = price_list;
            setPriceDetails({
                S40_price,
                S55_price,
                S70_price,
                currency_code,
                currency_sign,
            });
        } catch (err) {
            console.log(err);
        }
        setLoadingPrice(false);
    }

    useEffect(() => {
        (async () => {
            try {
                await loadPriceDetails();
            } catch (err) {
                throw new Error(err);
            }
        })();
    }, [country]);

    return (
        <div className={isAddToCartOpen ? 'overlay' : ''}>
            <div className='home-container'>
                <TopHeader/>

                {showing === 'home' ? (
                    <video
                        className='frist-video'
                        loop
                        autoPlay="autoplay"
                        muted
                        playsInline=''
                        preload='auto'
                        id='index_video'
                        src={'/images/video.webm'}
                        poster={'/images/fallbackimage.jpg'}
                    >
                        <source
                            src='/images/video.mp4'
                            type='video/mp4'
                        />
                        <source
                            src='/images/video.webm'
                            type='video/webm'
                        />
                    </video>
                ) : <></>}

                <Slides/>
            </div>

            {(floatPictures.length || filteredFloatPictures.length) ? (
                <TempImages/>
            ) : <></>}
        </div>
    );
};
export default HomeContents;

import UseWindowDimensions from '../../../../components/snippets/use-window-dimensions';
import {usePage} from '../../../../contexts/PageContext';
import ZoomableImage from '../../../../components/snippets/zoom/Zoomable';
import {Fragment} from "react";

const TouchDeviceOption = ({item, setShowBorderSwitchId}) => {
    const {height} = UseWindowDimensions();

    const ORDER_ITEM_IMAGE = {
        'TrueBorder 20': 'TB',
        'TrueBorder 40': 'TB',
        'Black and White 20': 'BW',
        'Black and White 40': 'BW',
    };

    const {
        showing,
        currentSlider,
    } = usePage();

    const isOrdersSlider = showing === 'orderList' && currentSlider === 'orders';

    const getImage = (item) => {
        if (isOrdersSlider) {
            return ORDER_ITEM_IMAGE[item?.itemSize] === 'TB' ? item?.imgTB : item?.imgBW;
        }
        return item.img;
    }

    return (
        <Fragment>
            <ZoomableImage
                src={getImage(item)}
                initialHeight={height}
                item={item}
                onClickImage={() => {
                    if (isOrdersSlider) return
                    setShowBorderSwitchId((value) => !value ? item?.art_id : null);
                }}
            />
        </Fragment>
    );
};
export default TouchDeviceOption;

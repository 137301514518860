import {Row} from 'react-bootstrap';
import CookieCondition from '../cookie-condition/CookieCondition.View';
import './FloatPicWebLocation.View.scss';
import React, {Fragment, memo} from "react";
import appConfig, {appSubdomain} from "../../../utils/config";

const FloatPicWebLocation = memo(() => {
    const handelSelect = (country) => {
        window.location.href = appSubdomain(country);
    }

    return (
        <div className='floatpic-web-location-container container-fluid '>
            <Row>
                <div className='inner-location-container'>
                    <div className='img-container'>
                        <img src='/images/splash-logo1.png'
                             loading="lazy" alt='Floatpic Home'/>
                    </div>
                    <Fragment>
                        <p className='location-header'>
                            Select a location to continue to FloatPic.com
                        </p>
                        <ul className='location-list'>
                            {Object.keys(appConfig.countries).map((key) => (
                                <li className='location-list-item' key={key}>
                                    <span className='location-list-item-link'
                                          onClick={() => handelSelect(key)}>
                                        {appConfig.countries[key].name}
                                    </span>
                                </li>
                            ))}
                            {/*<li className='location-list-item'>*/}
                            {/*        <span className='location-list-item-link' onClick={() => handelSelect('us')}>*/}
                            {/*            United States*/}
                            {/*        </span>*/}
                            {/*</li>*/}
                            {/*<li className='location-list-item'>*/}
                            {/*        <span className='location-list-item-link' onClick={() => handelSelect('ca')}>*/}
                            {/*            Canada*/}
                            {/*        </span>*/}
                            {/*</li>*/}
                            {/*<li className='location-list-item'>*/}
                            {/*        <span className='location-list-item-link' onClick={() => handelSelect('uk')}>*/}
                            {/*            United Kingdom*/}
                            {/*        </span>*/}
                            {/*</li>*/}
                            {/*<li className='location-list-item'>*/}
                            {/*        <span className='location-list-item-link' onClick={() => handelSelect('au')}>*/}
                            {/*            Australia*/}
                            {/*        </span>*/}
                            {/*</li>*/}
                            <li className='location-list-item'>
                                    <span className='location-list-item-link' onClick={() => handelSelect('US')}>
                                        All other countries
                                    </span>
                            </li>
                        </ul>
                    </Fragment>
                </div>
            </Row>

            <CookieCondition/>
        </div>
    );
});
export default FloatPicWebLocation;

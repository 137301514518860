import './BorderSwitcher.View.scss';
import {usePage} from '../../../contexts/PageContext';

const BorderSwitcher = ({onSwitchBorder, onClickAddToOrder, isOpen, item}) => {
    const {
        borderPreference,
        setZoomedProductId,
        zoomed,
        zoomedProduct,
        setZoomedProduct,
    } = usePage();

    return (
        <div
            className={`border-switcher ${
                isOpen ? 'border-switcher--show' : ''
            }`}
        >
            <div
                className='border-switcher__zoom'
                onClick={() => {
                    setZoomedProductId(item?.art_id);

                    if (zoomedProduct && zoomedProduct.art_id === item?.art_id) {
                        setZoomedProduct(null)
                    } else {
                        setZoomedProduct(item);
                    }
                }}
            >
                <img
                    src={zoomed ? '/images/zoom-close.png' : '/images/zoom.png'}
                    alt={zoomed ? 'zoom out' : 'zoom in'}
                    className='border-switcher__zoom-icon'
                    loading="lazy"
                />
            </div>
            <div className='border-switcher__switch'>
                <div className='box-container'>
                    <div
                        className={`single-box ${
                            borderPreference === 'TB' || borderPreference === '-TB'
                                ? 'active-tab'
                                : ''
                        }`}
                        onClick={() => {
                            if (borderPreference === 'TB' || borderPreference === '-TB') {
                                return;
                            }
                            // setActiveSwitch('TB');
                            onSwitchBorder();
                        }}
                    >
                        <p
                            className={`box-heading ${
                                borderPreference === 'TB' || borderPreference === '-TB'
                                    ? 'active-box-heading'
                                    : ''
                            }`}
                        >
                            TrueBorder
                        </p>
                    </div>
                    <div
                        className={`single-box ${
                            borderPreference === '-BW' || borderPreference === 'BW'
                                ? 'active-tab'
                                : ''
                        }`}
                        onClick={() => {
                            if (borderPreference === '-BW' || borderPreference === 'BW') {
                                return;
                            }
                            // setActiveSwitch('BW');
                            onSwitchBorder();
                        }}
                    >
                        <p
                            className={`box-heading ${
                                borderPreference === '-BW' || borderPreference === 'BW'
                                    ? 'active-box-heading'
                                    : ''
                            }`}
                        >
                            Black / White
                        </p>
                    </div>
                </div>
            </div>
            <div
                className='border-switcher__cart-btn'
                onClick={(event) => {
                    onClickAddToOrder(event);
                }}
            >
                <img
                    src='/images/addtoorder.png'
                    className=''
                    alt='add to order'
                    loading="lazy"
                />
            </div>
        </div>
    );
};

export default BorderSwitcher;
